<div class="table-container mat-elevation-z1">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">
  
    <!-- Sample Column -->
    <ng-container matColumnDef="sample">
      <th mat-header-cell *matHeaderCellDef> Sample </th>
      <td mat-cell *matCellDef="let e"> {{e.sampleName}} </td>
    </ng-container>
  
    <!-- Condition Column -->
    <ng-container matColumnDef="condition">
      <th mat-header-cell *matHeaderCellDef> Condition </th>
      <td mat-cell *matCellDef="let e"> {{e.conditionName}} </td>
    </ng-container>

    <!-- Dynamic cols -->
    <ng-container *ngFor="let dc of experimentResults?.dynamicColumns" [matColumnDef]="dc">
        <th mat-header-cell *matHeaderCellDef> {{dc}} </th>
        <td mat-cell *matCellDef="let e"> {{e[dc]?.positive}}/{{e[dc]?.negative}}/{{e[dc]?.invalid}}/{{e[dc]?.unevaluable}} </td>
    </ng-container>
    
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

  <mat-paginator [pageSizeOptions]="[10, 25, 50]" aria-label=""></mat-paginator>
