import { Component, OnInit } from '@angular/core';
import { LabpartnerService } from '../services/labpartner.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    httpRequestCount: Observable<number>;

    constructor(
        private labPartnerService: LabpartnerService
    ) {
        this.httpRequestCount = this.labPartnerService.httpRequestCount$;
    }

    ngOnInit(): void { }
}
