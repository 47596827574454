import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
    transform(value: Date | string | number): string {
        if (!value) return 'Invalid date';

        const date = new Date(value);
        const now = new Date();
        const seconds = Math.floor((+now - +date) / 1000);

        if (seconds < 29) return 'Just now';

        const intervals: { [key: string]: number } = {
            year: 31536000,
            month: 2592000,
            week: 604800,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1,
        };

        for (const key in intervals) {
            const interval = Math.floor(seconds / intervals[key]);
            if (interval >= 1) {
                return interval === 1 ? `${interval} ${key} ago` : `${interval} ${key}s ago`;
            }
        }
        return '';
    }
}