<div class="search-div">
  <button
    mat-raised-button
    matTooltip="Add individual logistic"
    (click)="onCreate()"
    [disabled]="
      !(currentRoles | hasAnyRoles : ['READER', 'WRITER'] : currentExperiment : 'IsNotComplete') ||
      (currentExperiment | statusIs : ['Complete'])
    ">
    <mat-icon>add</mat-icon>Create
  </button>
  <span class="p-input-icon-right">
    <input
      type="text"
      pInputText
      placeholder="Search"
      autocomplete="off"
      [(ngModel)]="searchKey"
      (ngModelChange)="onChange($event)" />
    <span pRipple class="input-end-icon ripple-grey" (click)="onSearchClear()">
      <i class="pi pi-times" *ngIf="searchKey"></i>
    </span>
  </span>
  <!-- To honor Logistic Import/Append, remove the [overrideIsImportStatus] binding -->
  <!-- Note: Must also update changedJSON messaging and performImport(...)'s TODO -->
  <app-xls-import
    matTooltip="Import logistics from excel spread sheet"
    [overrideIsImportStatus]="true"
    (jsonChanged)="changedJSON($event)"
    [disabled]="
      !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsNotComplete') ||
      (currentExperiment | statusIs : ['Complete'])
    ">
  </app-xls-import>
  <mat-icon
    *ngIf="logisticsAuditCountMap && logistic && logisticsAuditCountMap[logistic.logisticId] > 0"
    class="audit-count-icon"
    matTooltip="{{
      logisticsAuditCountMap && logistic && logisticsAuditCountMap[logistic.logisticId]
    }} change log records"
    matBadgeColor="warn"
    (click)="showAuditDialog(logistic)">
    receipt_long
  </mat-icon>

  <div class="progress-bar-container" *ngIf="apiService.isLoading$ | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
<div class="mat-elevation-z1">
  <mat-table [dataSource]="listData" matSort>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="value">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Value</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.value }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
          mat-icon-button
          matTooltip="Edit logistic"
          (click)="onEdit(row)"
          [disabled]="
            !(currentRoles | hasAnyRoles : ['READER', 'WRITER'] : currentExperiment : 'IsNotComplete') ||
            (currentExperiment | statusIs : ['Complete'])
          ">
          <mat-icon> edit </mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Delete logisitic"
          (click)="onDelete(row)"
          [disabled]="
            !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsNotComplete') ||
            (currentExperiment | statusIs : ['Complete'])
          ">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6"> No data. </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{ hide: listData != null }"></mat-footer-row>
    <mat-footer-row
      *matFooterRowDef="['noData']"
      [ngClass]="{ hide: !(listData != null && listData.data.length == 0) }"></mat-footer-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>
