<!-- Title Container -->
<mat-toolbar *ngIf="isInDialog" color="primary" class="title">
    <span>Data Insights Preview</span>
</mat-toolbar>
<div class="body-container">
    <!-- Header Actions Section -->
    <div class="view-actions">
        <button #dipGenerateReportBtn mat-raised-button color="light" (click)="addAutomatedReport()">
            Generate Report
        </button>
        <button color="light" mat-mini-fab (click)="loadAutomatedReports()"
            matTooltip="Refresh Data Insights Preview"><mat-icon>refresh</mat-icon></button>
        <div class="separator"></div>
        <mat-slide-toggle color="primary" [(ngModel)]="showArchivedAutomatedReports" (change)="setAutomatedReports()">
            <span>Show archived Data Insights Previews</span>
        </mat-slide-toggle>
    </div>
    <!-- Table Container -->
    <div class="table-container mat-elevation-z1">
        <table mat-table #table [dataSource]="dataSource">
            <!-- AutomatedReport ID -->
            <ng-container matColumnDef="automatedReportId">
                <th mat-header-cell *matHeaderCellDef> Report ID </th>
                <td mat-cell *matCellDef="let row"> {{row.automatedReportId}} </td>
            </ng-container>

            <!-- AutomatedReport name -->
            <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef> File Name </th>
                <td mat-cell *matCellDef="let row">
                    <div class="file-name-container">
                        {{getFileName(row)}}
                        <button [style.visibility]="!row.isActive ? 'visible' : 'hidden'" mat-icon-button
                            (click)="unarchiveAutomatedReport(row)" matTooltip="Unarchive the Data Insights Preview"
                            [disabled]="!((currentRoles | hasAnyRoles : ['ADMIN'] : null : 'IsAnyStatus') || user?.userId == row.createdBy)">
                            <mat-icon>unarchive</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <!-- AutomatedReport createdBy -->
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef> Created By </th>
                <td mat-cell *matCellDef="let row"> {{row.createdByName}} </td>
            </ng-container>
            <!-- AutomatedReport dateCreated -->
            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef> Date and Time </th>
                <td mat-cell *matCellDef="let row"> 
                    <span matTooltip="{{row.dateCreated | date : 'MM/dd/yy h:mma z'}}">
                        {{row.dateCreated | timeAgo }} 
                    </span>
                </td>
            </ng-container>
            <!-- AutomatedReport Actions -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let row">
                    <div class="actions">
                        <button mat-icon-button matTooltip="Download the Data Insights Preview"
                            *ngIf="row.status == AutomatedReportStatus.Completed"
                            (click)="downloadAutomatedReport(row)">
                            <mat-icon>download</mat-icon>
                        </button>
                        <div *ngIf="row.status == AutomatedReportStatus.Created">
                            <mat-progress-spinner class="spinner" *ngIf="itsReportOntime(row)"
                                matTooltip="This report is being created. This will take approximately 5 minutes." class="spinner"
                                mode="indeterminate" diameter="24">
                            </mat-progress-spinner>
                            <button mat-icon-button *ngIf="!itsReportOntime(row)"
                                matTooltip="Report generation failed, please try again">
                                <mat-icon class="error">warning</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="row.status == AutomatedReportStatus.Failed">
                            <button mat-icon-button
                                matTooltip="This report could not be generated, try again generating a new one">
                                <mat-icon class="error">warning</mat-icon>
                            </button>
                        </div>
                        <div>
                            <button [style.visibility]="row.isActive ? 'visible' : 'hidden'" mat-icon-button
                                (click)="archiveAutomatedReport(row)" matTooltip="Archive the Data Insights Preview"
                                [disabled]="!((currentRoles | hasAnyRoles : ['ADMIN'] : null : 'IsAnyStatus') || user?.userId == row.createdBy)">
                                <mat-icon>archive</mat-icon>
                            </button>
                        </div>
                        <div>
                            <button [style.visibility]="row.hasDuplicateResults ? 'visible' : 'hidden'" mat-icon-button
                                matTooltip="This report has duplicated results">
                                <mat-icon>warning</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
            <!-- AutomatedReport dailyExternalControls -->
            <ng-container matColumnDef="dailyExternalControls">
                <th mat-header-cell *matHeaderCellDef> Daily External Controls </th>
                <td mat-cell *matCellDef="let row"> {{row.dailyExternalControlExperimentId}} </td>
            </ng-container>
            <!-- AutomatedReport Actions -->
            <ng-container matColumnDef="actions2">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <!-- Footer Actions  -->
    <div class="footer mat-elevation-z1">
        <div class="separator"></div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>
</div>