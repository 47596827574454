import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SampleService } from 'src/app/shared/sample.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { MatSort } from '@angular/material/sort';
import { DialogService } from 'src/app/shared/dialog.services';
import {
  AuditedChangeResponse,
  CreatedStatus,
  DetailsGeneratedStatus,
  Experiment,
  ExperimentType,
  InputsHeaderOrder,
  ReadyToTestStatus,
  Sample,
  StudyType,
} from 'src/app/services/labpartner.service.model';
import { BaseComponent } from 'src/app/support/base.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { HttpStatusCode } from '@angular/common/http';
import { NotificationService } from 'src/app/shared/notification.service';
import { IChangeDialogResult, IChangeSet, IReplicatePair } from 'src/app/changes-dialog/changes-dialog.component';
import { AuditObjectIdCountMap, AuditObjectName } from 'src/app/services/audit.service.models';
import { EVENT_CREATE_SAMPLES_NEW_FLOW, EVENT_DELETE_ANALYTE, EVENT_DELETE_SAMPLE, EVENT_EDIT_SAMPLES, EVENT_RENAME_HEADERS_SAMPLES } from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { DetailsService } from 'src/app/services/details.service';
import { AddColumnDialogComponent, IAddColumnResult } from 'src/app/add-column-dialog/add-column-dialog.component';
import { ConditionListComponent } from 'src/app/conditions/condition-list/condition-list.component';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { INPUT_TYPES } from 'src/app/services/labpartner-constants';
import { is } from 'cypress/types/bluebird';
import { last } from 'cypress/types/lodash';
import { SampleColumns } from './sample-columns.config';
import { AnalyteColumns } from './analyte-columns.config';

interface KeyboardEventInHtmlInput extends KeyboardEvent {
  target: HTMLInputElement;
}

export interface ISampleDisplayItem {
  [key: string]: any;
  id: number;
  sampleId: number;
  label: string;
  name: string;
  unevaluable: boolean;
  data: { [key: string]: any };
  analytes: any[];
}

@Component({
  selector: 'app-sample-list',
  templateUrl: './sample-list.component.html',
  styleUrls: ['./sample-list.component.scss'],
})
export class SampleListComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() experimentId: number = 0;
  @Input() samplesNotesCountMap?: { [key: number]: number };
  @Input() samplesAuditCountMap?: AuditObjectIdCountMap;
  @Input() conditionsListCmp?: ConditionListComponent;
  @Input() currentExperiment: Experiment | null = null;
  @Input() currentRoles: string[] = [];

  @Output() isEditing: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  modifiedListData: { [key: number]: ISampleDisplayItem } = {};
  listData!: MatTableDataSource<ISampleDisplayItem>;
  objectName = 'Samples';

  isEditMode: boolean = false;
  postEditLoading: boolean = false;
  newestRowIndex: number = 0;
  newestSampleLabel: string = '';

  prevPageSize: number = 0;
  unsavedColumns: string[] = [];

  analyteUnsavedColumns: string[] = [];

  ReadyToTest = ReadyToTestStatus;
  StudyType = StudyType;

  headers: string[] = [];
  analyteHeaders: string[] = [];

  staticColumns: any[] = [];

  staticAnalyteColumns: any[] = [];

  public displayedColumns!: string[];
  // material table mat-header-cell and mat-cell requires only the property name (not the full property path)
  public propertyNames!: string[];
  public analytePropertyNames!: string[];

  public newPropertyNames: any = {};
  public analyteNewPropertyNames: any = {};
  // material table mat-header-row requires the full property path (e.g. data.temperature) so we build those up separately
  public propertyPaths!: string[];

  public searchKey: string = '';

  constructor(
    public apiService: LabpartnerService,
    public accountService: UserAccountService,
    public appState: AppStateService,
    private service: SampleService,
    private detailsService: DetailsService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private loggingService: LoggingService,
    private labPartnerService: LabpartnerService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFixedColumns();
    if (
      this.currentExperiment?.assayNameId &&
      (this.currentExperiment?.studyType == StudyType.LoDConfirmation ||
        this.currentExperiment?.studyType == StudyType.LoDRangeFinding ||
        this.currentExperiment?.studyType == StudyType.General ||
        this.currentExperiment?.studyType == StudyType.Precision)
    ) {
      this.apiService.getAnalyteTemplates(this.currentExperiment.assayNameId).subscribe((analytes: any[]) => {
        this.staticAnalyteColumns.find(x => x.prop == 'analyteTemplateId').options = [
          { value: 0, label: 'None' },
          ...analytes.map(x => {
            return { value: x.analyteTemplateId, label: x.name };
          })];
      });
    }
  }

  ngOnInit() {
    this.apiService.getSamplesList('experimentId', this.experimentId).subscribe((samples: Sample[]) => {

      this.listData = new MatTableDataSource();

      const displayItems = this.parseSamplesToSampleDisplayItems(samples);
      this.listData.data.push(...displayItems);
      this.parseSampleFromAPI();
      // to build this from the keys collection, have to prepend data in front of each property
      // start with the known column, then spread the rest in the array
      //if (this.listData.data.length > 0) {
      this.loadHeaderOrder();
      //}

      this.setListDataConfig();

      // If there are no samples, add a new row
      if (this.listData.data.length == 0 &&
        this.currentExperiment?.studyType != null &&
        this.currentExperiment?.studyType != undefined &&
        this.currentExperiment?.studyType != StudyType.ImportExcel) {
        this.isEditMode = true;
        this.appendNewRow();
        if (this.shouldShowAnalytes()) {
          this.appendNewAnalyte(this.listData.data[0].id);
        }
      }
    });

    this.subscription.add(
      this.accountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
      }));
  }

  protected ngOnDestroyInternal(): void {
    // required by base component. clean up any component specific resources
  }

  loadHeaderOrder() {
    this.analyteHeaders = this.parseAnalytePropertyNames();

    this.apiService.getInputsHeaderOrder(this.experimentId, INPUT_TYPES.SAMPLES).subscribe((res: InputsHeaderOrder) => {
      try {
        let headersJson = JSON.parse(res.headers);
        this.headers = Object.keys(headersJson).map((x, i) => headersJson[i]);
      }
      catch (ex) {
        this.headers = this.parsePropertyNames();
      }
      this.refreshPropertyPathsNamesAndDisplayedColumns();
      this.resetHeadersChanges();
    });
  }

  onSearchClear() {
    this.searchKey = '';
    this.onChange('');
  }

  onChange(newVal: string) {
    if (this.listData != null) {
      this.listData.filter = this.searchKey.trim().toLowerCase();
    }
  }

  onEdit(row: any) {
    this.service.populateForm(row);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
  }

  onDelete(row: any) {
    const sampleId = row.id;
    if (this.appState.GetCurrentExperiment().status == DetailsGeneratedStatus) {
      const dialog = this.dialogService.openConfirmDialog(
        'Are you sure you want to delete this sample, all associated details will be deleted as well ?'
      );

      dialog.afterClosed().subscribe(res => {
        if (res) {
          this.deleteSample(sampleId, row);
        }
      });
    } else {
      this.deleteSample(sampleId, row);
    }
  }

  async toggleSampleUnevaluable(sample: ISampleDisplayItem, evt: MatCheckboxChange) {
    const currentExperiment = this.appState.GetCurrentExperiment();

    const prevValue = sample.unevaluable;
    const newValue = !sample.unevaluable;

    sample.experimentId = currentExperiment.experimentId;
    sample.unevaluable = !sample.unevaluable;

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      [
        {
          identifier: `Sample ${sample.label} ${sample.name}`,
          field: 'Ignore',
          oldValue: prevValue ?? false,
          newValue: newValue,
        },
      ],
      this.appState.GetCurrentExperiment().type,
      {}
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || currentExperiment.type == ExperimentType.ResearchAndDevelopment)
      ) {
        await this.setSampleUnevaluableWithExceptionHandling(
          (sample as any), //TODO: fix this
          data.batchReason ?? '',
          evt.source,
          prevValue ?? false
        );
        this.appState.SetExperimentDataChanged(this.experimentId);
      } else {
        sample.unevaluable = !sample.unevaluable;
        evt.source.checked = prevValue ?? false;
      }
    });
  }

  onInputKeyDown(evt: KeyboardEvent): void {
    const kbEvt = evt as KeyboardEventInHtmlInput;

    let targetNum = 0;
    const currentNum = +kbEvt.target.id.slice(kbEvt.target.id.lastIndexOf('-') + 1);
    const userIsNotSelecting = kbEvt.target.selectionStart == kbEvt.target.selectionEnd;

    switch (kbEvt.key) {
      case 'ArrowLeft':
        if (kbEvt.target.selectionStart == 0 && userIsNotSelecting) {
          targetNum = currentNum - 1;
        }
        break;
      case 'ArrowRight':
        if (kbEvt.target.selectionStart == kbEvt.target.value.length && userIsNotSelecting) {
          targetNum = currentNum + 1;
        }
        break;
      case 'ArrowUp':
        targetNum = currentNum - this.propertyNames.length;
        break;
      case 'ArrowDown':
        targetNum = currentNum + this.propertyNames.length;
        break;
    }

    if (targetNum != 0) {
      const newTarget = document.getElementById(`mat-input-${targetNum}`) as HTMLInputElement;

      if (newTarget && newTarget.classList.contains('modifiable-input')) {
        setTimeout(() => {
          newTarget.focus();
          newTarget.setSelectionRange(0, newTarget.value.length);
        });
      }
    }
  }

  async onSamplesEdit(): Promise<void> {
    // Set the paginator to display all rows
    this.prevPageSize = this.listData.paginator!.pageSize;
    this.paginator!._changePageSize(this.listData.data.length);
    this.paginator!.firstPage();

    if (this.listData.data.length > 0) {
      this.newestRowIndex = this.listData.data.length - 1;
      this.newestSampleLabel = this.listData.data
        .filter(x => x.id > 0)
        .map(x => x.label)
        .sort()
      [this.listData.data.length - 1].slice(1);
    }

    this.isEditing.emit(true);
    this.isEditMode = true;
    this.modifiedListData = {};
    // Copy the data to modifiedListData
    for (let listItem of this.listData.data || []) {
      this.modifiedListData[listItem.id] = JSON.parse(JSON.stringify(listItem));
    }
  }

  onSamplesEditCommit = async (): Promise<void> => {
    // Copy the modifiedListData to a new object
    let modifiedListData: any = JSON.parse(JSON.stringify(this.modifiedListData));

    let newPropertyNames = JSON.parse(JSON.stringify(this.newPropertyNames));
    let newPropertyNamesReversed: any = {};
    Object.keys(newPropertyNames).forEach(propertyName => {
      newPropertyNamesReversed[newPropertyNames[propertyName]] = propertyName
    });

    let analyteNewPropertyNames = JSON.parse(JSON.stringify(this.analyteNewPropertyNames));
    let analyteNewPropertyNamesReversed: any = {};
    Object.keys(analyteNewPropertyNames).forEach(propertyName => {
      analyteNewPropertyNamesReversed[analyteNewPropertyNames[propertyName]] = propertyName
    });


    let newHeaders = [...this.headers.map(x => newPropertyNames[x]), ...this.unsavedColumns.map(x => newPropertyNames[x])];

    // Validate if there are duplicated columns
    if (new Set(newHeaders).size !== newHeaders.length) {
      this.notificationService.error('Duplicated column.');
      return;
    }

    // Replace new property names in the data
    this.propertyNames.forEach(propertyName => {
      var newPropertyName = newPropertyNames[propertyName];
      if (propertyName != newPropertyName) {
        for (let sample of this.listData.data) {
          modifiedListData[sample.id].data[newPropertyName] = modifiedListData[sample.id].data[propertyName];
          // If old header does not exist in new headers, delete it
          if (!newHeaders.find(x => x == propertyName)) {
            delete modifiedListData[sample.id].data[propertyName];
          }
        }
      }
    });

    let newAnalyteHeaders = [...this.analyteHeaders.map(x => analyteNewPropertyNames[x]), ...this.analyteUnsavedColumns.map(x => analyteNewPropertyNames[x])];
    // Replace new property names in the analyte data
    this.analytePropertyNames.forEach(propertyName => {
      var newPropertyName = analyteNewPropertyNames[propertyName];
      if (propertyName != newPropertyName) {
        for (let sample of this.listData.data) {
          if (sample.analytes) {
            for (let analyte of sample.analytes) {
              var modifiedAnalyte = modifiedListData[sample.id].analytes.find((x: any) => x.analyteId == analyte.analyteId);
              modifiedAnalyte.data[newPropertyName] = modifiedAnalyte.data[propertyName];
              // If old header does not exist in new headers, delete it
              if (!newAnalyteHeaders.find(x => x == propertyName)) {
                delete modifiedAnalyte.data[propertyName];
              }
            }
          }
        }
      }
    });

    // Validations for empty fields
    const newRows = Object.keys(modifiedListData).filter(x => +x <= 0);
    const newRowKeys: number[] = newRows.map(x => +x);
    let newRowCount: number = newRowKeys.length;

    let changeSet: IChangeSet[] = [];
    let modifiedSamples: Sample[] = [];
    let newSamples: Sample[] = [];
    let numberOfModifiedCells = 0;

    // Iterate over all samples to check if any value has changed
    for (let originalItem of this.listData.data) {
      const originalItemData = originalItem.data;
      const modifiedItem = modifiedListData[originalItem.id];
      const modifiedItemData = modifiedItem.data;


      if (this.currentExperiment?.studyType == StudyType.LoDConfirmation ||
        this.currentExperiment?.studyType == StudyType.LoDRangeFinding ||
        this.currentExperiment?.studyType == StudyType.General
      ) {
        let analytes = modifiedItem.analytes;
        // Check if at least one analyte row is required for each sample
        if (!analytes || analytes.length == 0) {
          this.notificationService.error('At least one analyte row is required for each sample.');
          return;
        }

        let analyteNameIds = analytes.map((a: any) => +a.analyteTemplateId);
        // Check if there is duplicated analyte names
        if ((new Set(analyteNameIds)).size !== analyteNameIds.length) {
          this.notificationService.error('Each analyte name of each sample should be different.');
          return;
        }
      }

      // Check if fixed columns values has changed
      let hasChanges = false;

      // Existing samples
      if (originalItem.id > 0) {
        // Check if fixed columns values has changed
        if (this.currentExperiment?.studyType != StudyType.ImportExcel) {
          // Check if fixed columns values has changed
          for (let staticCol of this.staticColumns) {
            // Check if fixed column is required and empty
            if (staticCol.required && !modifiedItem[staticCol.prop]) {
              this.notificationService.error('All fields are required.');
              return;
            }

            if (originalItem[staticCol.prop] != modifiedItem[staticCol.prop]) {
              changeSet.push({
                identifier: `Sample ${originalItem.label}`,
                field: staticCol.prop,
                oldValue: originalItem[staticCol.prop],
                newValue: modifiedItem[staticCol.prop],
              });
              if (!hasChanges) {
                hasChanges = true;
                let modifiedSample: any = {
                  sampleId: originalItem.sampleId,
                  experimentId: this.experimentId,
                  name: modifiedItem.name,
                  importData: JSON.stringify(modifiedItemData),
                  analytes: modifiedItem.analytes?.map((a: any) => {
                    a.importData = JSON.stringify(a.data);
                    return a;
                  }) // If the sample is new just add the analytes
                };
                this.staticColumns.forEach((col) => {
                  modifiedSample[col.prop] = modifiedItem[col.prop];
                });
                modifiedSamples.push(modifiedSample);
              }
            }
          }
        }

        // Check if existing samples values has changed
        if (JSON.stringify(originalItemData) != JSON.stringify(modifiedItemData)) {
          if (!hasChanges) {
            hasChanges = true;
            let modifiedSample: any = {
              sampleId: originalItem.sampleId,
              experimentId: this.experimentId,
              name: modifiedItemData[Object.keys(modifiedItemData)[0]],
              importData: JSON.stringify(modifiedItemData),
              analytes: modifiedItem.analytes?.map((a: any) => {
                a.importData = JSON.stringify(a.data);
                return a;
              }) // If the sample is new just add the analytes
            }
            // Add fixed columns values
            this.staticColumns.forEach((col) => {
              modifiedSample[col.prop] = modifiedListData[originalItem.id][col.prop];
            });
            modifiedSamples.push(modifiedSample);
          }

          for (let field of Object.keys(modifiedItemData)) {
            if (originalItemData[field] != modifiedItemData[field] &&
              (field == newPropertyNames[field]) || this.unsavedColumns.find(x => x === field) || this.unsavedColumns.find(x => x === newPropertyNamesReversed[field])) {
              numberOfModifiedCells++;

              changeSet.push({
                identifier: `Sample ${originalItem.label}`,
                field: field,
                oldValue: originalItemData[field],
                newValue: modifiedItemData[field],
                data: JSON.stringify(modifiedItemData),
              });
            }

            if (originalItemData[newPropertyNamesReversed[field]] != modifiedItemData[field] &&
              (field != newPropertyNames[field]) && !this.unsavedColumns.find(x => x === field) && !this.unsavedColumns.find(x => x === newPropertyNamesReversed[field])) {
              changeSet.push({
                identifier: `Sample ${originalItem.label}`,
                field: newPropertyNamesReversed[field],
                oldValue: originalItemData[newPropertyNamesReversed[field]],
                newValue: modifiedItemData[field],
                data: JSON.stringify(modifiedItemData),
              });
            }
          }
        }
        let newAnalytesRow = modifiedItem.analytes.filter((a: any) => a.analyteId <= 0);

        if (JSON.stringify(originalItem.analytes) != JSON.stringify(modifiedItem.analytes) || newAnalytesRow.length > 0) {
          if (!hasChanges) {
            hasChanges = true
            let modifiedSample: any = {
              sampleId: originalItem.sampleId,
              experimentId: this.experimentId,
              name: modifiedItemData[Object.keys(modifiedItemData)[0]],
              importData: JSON.stringify(modifiedItemData),
              analytes: modifiedItem.analytes?.map((a: any) => {
                a.importData = JSON.stringify(a.data);
                return a;
              }) // If the sample is new just add the analytes
            }
            // Add fixed columns values
            this.staticColumns.forEach((col) => {
              modifiedSample[col.prop] = modifiedListData[originalItem.id][col.prop];
            });
            modifiedSamples.push(modifiedSample);
          }

          let oldAnalytesRow = originalItem.analytes.filter((a: any) => a.analyteId > 0);

          for (let originalAnalyte of oldAnalytesRow) {
            let modifiedAnalyte = modifiedItem.analytes.find((x: any) => x.analyteId == originalAnalyte.analyteId);
            let modifiedAnalyteData = modifiedAnalyte.data;
            let originalAnalyteData = originalAnalyte.data;
            for (let staticCol of this.staticAnalyteColumns) {
              // Check if fixed column is required and empty
              if (modifiedAnalyte.analyteTemplateId != 0) {
                if (staticCol.required && modifiedAnalyte.analyteTemplateId != 0) {
                  if (staticCol.inputType == 'number' && staticCol.minValue !== undefined) {
                    if (+modifiedAnalyte[staticCol.prop] < staticCol.minValue) {
                      this.notificationService.error(staticCol.header + ' min value is ' + staticCol.minValue);
                      return;
                    }
                  } else if (!modifiedAnalyte[staticCol.prop]) {
                    this.notificationService.error('All fields are required.');
                    return;
                  }
                }
              }
              if (originalAnalyte[staticCol.prop] != modifiedAnalyte[staticCol.prop]) {
                changeSet.push({
                  identifier: `Sample ${originalItem.label} - Analyte ${originalAnalyte.name}`,
                  field: staticCol.prop,
                  oldValue: originalAnalyte[staticCol.prop],
                  newValue: modifiedAnalyte[staticCol.prop],
                });
              }
            }

            // Check if existing analytes values has changed
            if (JSON.stringify(originalAnalyteData) != JSON.stringify(modifiedAnalyteData)) {
              if (!hasChanges) {
                hasChanges = true;
                let modifiedSample: any = {
                  sampleId: originalItem.sampleId,
                  experimentId: this.experimentId,
                  name: modifiedItemData[Object.keys(modifiedItemData)[0]],
                  importData: JSON.stringify(modifiedItemData),
                  analytes: modifiedItem.analytes?.map((a: any) => {
                    a.importData = JSON.stringify(a.data);
                    return a;
                  }) // If the sample is new just add the analytes
                }
                // Add fixed columns values
                this.staticColumns.forEach((col) => {
                  modifiedSample[col.prop] = modifiedListData[originalItem.id][col.prop];
                });
                modifiedSamples.push(modifiedSample);
              }

              for (let field of Object.keys(modifiedAnalyteData)) {
                if (originalAnalyteData[field] != modifiedAnalyteData[field] &&
                  (field == analyteNewPropertyNames[field]) || this.analyteUnsavedColumns.find(x => x === field) || this.analyteUnsavedColumns.find(x => x === analyteNewPropertyNamesReversed[field])) {
                  changeSet.push({
                    identifier: `Sample ${originalItem.label} - Analyte ${originalAnalyte.name}`,
                    field: field,
                    oldValue: originalAnalyteData[field],
                    newValue: modifiedAnalyteData[field],
                    data: JSON.stringify(modifiedAnalyteData),
                  });
                }

                if (originalAnalyteData[analyteNewPropertyNamesReversed[field]] != modifiedAnalyteData[field] &&
                  (field != analyteNewPropertyNames[field]) && !this.analyteUnsavedColumns.find(x => x === field) && !this.analyteUnsavedColumns.find(x => x === analyteNewPropertyNamesReversed[field])) {
                  changeSet.push({
                    identifier: `Sample ${originalItem.label} - Analyte ${originalAnalyte.name}`,
                    field: analyteNewPropertyNamesReversed[field],
                    oldValue: originalAnalyteData[analyteNewPropertyNamesReversed[field]],
                    newValue: modifiedAnalyteData[field],
                    data: JSON.stringify(modifiedAnalyteData),
                  });
                }
              }
            }
          }

          if (newAnalytesRow.length > 0) {
            changeSet.push({
              identifier: `Sample ${originalItem.label}`,
              field: 'Analytes',
              oldValue: `${oldAnalytesRow.length} analytes`,
              newValue: `+ ${newAnalytesRow.length} new analytes`,
            });
          };
        }
      }
      // New samples
      if (originalItem.id <= 0) {
        const missingValues = Object.values(modifiedItemData)
          .filter((x: any) => !!!x)
          .map((x: any) => x);

        // Check if fixed columns values are filled
        for (let staticCol of this.staticColumns) {
          // Check if fixed column is required and empty
          if (staticCol.required && !modifiedItem[staticCol.prop]) {
            this.notificationService.error('All fields are required.');
            return;
          }
        }

        let newItem: any = {
          sampleId: 0,
          experimentId: this.experimentId,
          name: modifiedItem.name,
          importData: JSON.stringify(modifiedItemData),
          analytes: modifiedItem.analytes?.map((a: any) => {
            a.importData = JSON.stringify(a.data);
            return a;
          }) // If the sample is new just add the analytes
        };
        // Add fixed columns values
        this.staticColumns.forEach((col) => {
          newItem[col.prop] = modifiedItem[col.prop];
        });
        newSamples.push(newItem);
      }

      // Validate analytes
      if (originalItem.analytes) {
        for (let originalAnalyte of originalItem.analytes) {
          let modifiedAnalyte = modifiedItem.analytes.find((x: any) => x.analyteId == originalAnalyte.analyteId);
          let modifiedAnalyteData = modifiedAnalyte.data;
          // New analytes
          // Only validate if analyte is not none
          if (modifiedAnalyte.analyteTemplateId != 0) {
            if (originalAnalyte.analyteId <= 0) {
              const missingValues = Object.values(modifiedAnalyteData)
                .filter((x: any) => !!!x)
                .map((x: any) => x);

              // Check if fixed columns values are filled
              for (let staticCol of this.staticAnalyteColumns) {
                // Check if fixed column is required and empty
                if (staticCol.required && !modifiedAnalyte[staticCol.prop] && modifiedAnalyte.analyteTemplateId != 0 && staticCol.minValue === undefined) {
                  this.notificationService.error('All fields are required.');
                  return;
                }
                if (staticCol.required && staticCol.minValue !== undefined) {
                  if (+modifiedAnalyte[staticCol.prop] < staticCol.minValue) {
                    this.notificationService.error(staticCol.header + ' min value is ' + staticCol.minValue);
                    return;
                  }
                }
              }
            }
          }
        }
      }
    }

    Object.keys(analyteNewPropertyNames).forEach(p => {
      if (analyteNewPropertyNames[p] != p && !this.analyteUnsavedColumns.find(x => x === p)) {
        changeSet.push({
          identifier: AuditObjectName.Samples,
          field: 'Analyte Header Renamed',
          oldValue: p,
          newValue: analyteNewPropertyNames[p],
        });
      }
    });

    Object.keys(newPropertyNames).forEach(p => {
      if (newPropertyNames[p] != p && !this.unsavedColumns.find(x => x === p)) {
        changeSet.push({
          identifier: AuditObjectName.Samples,
          field: 'Sample Header Renamed',
          oldValue: p,
          newValue: newPropertyNames[p],
        });
      }
    });

    if (newRowCount > 0) {
      changeSet.push({
        identifier: AuditObjectName.Samples,
        field: '-',
        oldValue: '-',
        newValue: `+ ${newRowCount} new rows`,
      });
    }

    // Replicates
    let replicatePairs: IReplicatePair[] = [];

    // If the experiment is not in Created status, we need to create new combinations with the new samples
    if (this.currentExperiment?.status != CreatedStatus) {
      newSamples
        .sort((a, b) => b.sampleId - a.sampleId)
        .forEach((sample, index) => {
          sample.label = `S${(+this.newestSampleLabel + (Math.abs(index) + 1)).toString().padStart(2, '0')}`;
        });

      // New combinations with new Samples as base
      if (this.conditionsListCmp?.listData.data) {
        for (let sample of newSamples) {
          for (let condition of this.conditionsListCmp?.listData.data) {
            replicatePairs.push({
              sampleId: sample.sampleId,
              sampleLabel: sample.label,
              conditionId: condition.conditionId,
              conditionLabel: condition.label,
              numberOfReplicates: this.appState.GetCurrentExperiment().replicates,
            });
          }
        }
      }
    }

    // Validate if there are changes
    if (changeSet.length == 0) {
      this.notificationService.warn('No changes have been made yet');
      return;
    }

    if (this.listData.data.filter(d => d.id > 0).length > 0) {
      // Exists real data
      const dialogRef = this.dialogService.openConfirmChangesDialog(
        changeSet,
        this.appState.GetCurrentExperiment().type,
        {
          multiReason: true,
          isAppend: true,
          defaultReplicates: this.appState.GetCurrentExperiment().replicates,
          replicateData: replicatePairs,
        }
      );

      dialogRef.afterClosed().subscribe(async data => {
        if (
          data?.submitClicked &&
          (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
        ) {
          this.onCallEdit(newSamples, modifiedSamples, replicatePairs, data, numberOfModifiedCells);
        }
      });
    }
    else {
      // First time edit
      this.onCallEdit(newSamples, modifiedSamples, replicatePairs, { batchReason: "", fieldReasons: [''] } as IChangeDialogResult, numberOfModifiedCells);
    }

  }

  async onCallEdit(newSamples: Sample[], modifiedSamples: Sample[], replicatePairs: IReplicatePair[], data: IChangeDialogResult, numberOfModifiedCells: number) {
    // Makes "Loading data..." display again without breaking other type safety
    let copyListData = Object.assign([], this.listData.data);
    (this.listData as any) = undefined;
    this.postEditLoading = true;

    try {
      let renamedHeaders: any = {};
      let newPropertyNamesCopy = JSON.parse(JSON.stringify(this.newPropertyNames));
      Object
        .keys(newPropertyNamesCopy)
        .filter(p => p !== newPropertyNamesCopy[p] && !this.unsavedColumns.find(x => x == p))
        .forEach(p => {
          renamedHeaders[p] = newPropertyNamesCopy[p];
        });

      let renamedAnalyteHeaders: any = {};
      let analyteNewPropertyNamesCopy = JSON.parse(JSON.stringify(this.analyteNewPropertyNames));
      Object
        .keys(analyteNewPropertyNamesCopy)
        .filter(p => p !== analyteNewPropertyNamesCopy[p] && !this.analyteUnsavedColumns.find(x => x == p))
        .forEach(p => {
          renamedAnalyteHeaders[p] = analyteNewPropertyNamesCopy[p];
        });

      let headersOrder: any = {};
      [...this.headers.map(x => newPropertyNamesCopy[x]), ...this.unsavedColumns.map(x => newPropertyNamesCopy[x])].forEach((x, i) => {
        headersOrder[i] = x;
      });

      // Parse values before sending to API
      this.parseSampleToAPI([...newSamples, ...modifiedSamples])

      const refreshedSamples = await this.apiService.submitSampleChangesWithAuditingAsync(
        this.experimentId,
        replicatePairs.filter(rp => !rp.ignore && (rp.numberOfReplicates ?? 0) > 0),
        newSamples,
        modifiedSamples,
        renamedHeaders,
        renamedAnalyteHeaders,
        data?.batchReason ?? '',
        data.fieldReasons,
        JSON.stringify(headersOrder)
      );

      if (!refreshedSamples) { throw new Error("refreshedSamples is null"); }

      const headersProps: { [key: string]: number | string } = {
        ExperimentId: this.experimentId,
        RenamedHeadersCount: Object.keys(renamedHeaders).length,
        DeviceType: this.appState.GetCurrentExperiment().deviceType,
        ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
      };
      this.loggingService.logEvent(EVENT_RENAME_HEADERS_SAMPLES, headersProps);

      if (this.currentExperiment?.studyType != null && this.currentExperiment?.studyType != StudyType.ImportExcel) {
        const newSamplesProps: { [key: string]: number | string } = {
          ExperimentId: this.experimentId,
          DeviceType: this.appState.GetCurrentExperiment().deviceType,
          ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
          NumberCreated: copyListData.filter((x: any) => x.id <= 0).length,
          StudyType: this.currentExperiment?.studyType,
        };
        this.loggingService.logEvent(EVENT_CREATE_SAMPLES_NEW_FLOW, newSamplesProps);
      } else {
        const props: { [key: string]: number | string } = {
          ExperimentId: this.experimentId,
          AppendSampleCount: newSamples.length,
          UpdateSampleCount: modifiedSamples.length,
          ChangedSampleCellCount: numberOfModifiedCells,
          DeviceType: this.appState.GetCurrentExperiment().deviceType,
          ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
        };
        this.loggingService.logEvent(EVENT_EDIT_SAMPLES, props);
      }

      this.setNewListData(this.parseSamplesToSampleDisplayItems(refreshedSamples));
      this.refreshPropertyPathsNamesAndDisplayedColumns();
      this.resetHeadersChanges();

      this.loadHeaderOrder();
      this.appState.SetExperimentDataChanged(this.experimentId);
    } catch (err: any) {
      const expectedErr = err.error as AuditedChangeResponse;
      if (expectedErr.expectedException) {
        this.notificationService.error(expectedErr.message);
      } else {
        throw err;
      }
    }

    this.endEditMode();
  }

  restoreData(temporalRemoved: any, temporalModifiedListData: any, oldHeaders: string[]) {
    Object.keys(temporalRemoved).forEach(propertyName => {
      var newPropertyName = temporalRemoved[propertyName];
      for (let sample of this.listData.data) {
        this.modifiedListData[sample.id].data[propertyName] = this.modifiedListData[sample.id].data[newPropertyName];
        if (!oldHeaders.find(x => x == newPropertyName)) {
          delete this.modifiedListData[sample.id].data[newPropertyName];
        }
      }
    });
    this.refreshPropertyPathsNamesAndDisplayedColumns();
    this.resetHeadersChanges();
    Object.keys(temporalRemoved).forEach(propertyName => {
      this.newPropertyNames[propertyName] = temporalRemoved[propertyName]
    });
  }

  appendNewRow(sampleItem?: ISampleDisplayItem): void {
    if (this.listData.data.length >= LabpartnerService.MAX_RECORDS_PER_IMPORT) {
      this.notificationService.warn('Maximum Sample records is ' + LabpartnerService.MAX_RECORDS_PER_IMPORT);
      return;
    }

    const curModifiedListItems = Object.keys(this.modifiedListData).sort((a, b) => +a - +b);
    let newestRealItem = this.modifiedListData[+curModifiedListItems[curModifiedListItems.length - 1]];
    if (!newestRealItem) {
      newestRealItem = { data: {} } as any;
    }
    const firstItem = this.modifiedListData[+curModifiedListItems[0]];

    const newestAppendedItem = firstItem && firstItem.id < 0 ? firstItem : null;
    const newId = newestAppendedItem ? newestAppendedItem.id - 1 : -1;

    const newData: { [key: string]: any } = {};
    const newBlankData: { [key: string]: any } = {};
    for (let prop of Object.keys(newestRealItem.data)) {
      newBlankData[prop] = '';
      if (sampleItem) {
        newData[prop] = sampleItem.data[prop];
      }
    }

    const newItem = <ISampleDisplayItem>{
      id: newId,
      label: 'NewRow',
      name: sampleItem ? sampleItem.name : `NewRow`,
      sampleId: newId,
      unevaluable: false,
      data: sampleItem ? newData : newBlankData
    };

    // Add fixed columns values
    this.staticColumns.forEach((col) => {
      newItem[col.prop] = sampleItem ? sampleItem[col.prop] : ``;
    });

    this.modifiedListData[newId] = {
      ...newItem,
      data: { ...(sampleItem ? newData : newBlankData) },
    };

    this.listData.data.push({
      ...newItem,
      data: { ...newBlankData },
    });

    this.paginator!._changePageSize(this.listData.data.length);
    this.setNewListData(this.listData.data);

    setTimeout(() => {
      // Scroll to the new row
      const container = document.getElementById('samples-container');
      if (!container) return;
      const target = document.getElementById('sample-' + newItem.id);
      if (!target) return;
      target.scrollIntoView({
        behavior: 'smooth', // Enables smooth scrolling
        block: 'center',    // Aligns the element to the center of the container
        inline: 'nearest'   // Ensures it scrolls in the nearest direction
      });
    }, 250);
  }

  addColumn() {
    // Validate maximum columns
    if (Object.keys(this.listData.data[0].data).length + this.unsavedColumns.length >= LabpartnerService.MAX_SAMPLES_AND_CONDITIONS_COLUMNS) {
      this.notificationService.warn('Maximum number of dynamic columns is ' + LabpartnerService.MAX_SAMPLES_AND_CONDITIONS_COLUMNS);
      return;
    }

    const dialogRef = this.matDialog.open<AddColumnDialogComponent, any, IAddColumnResult>(AddColumnDialogComponent, {
      width: '300px',
      panelClass: 'add-column-dialog-container',
      disableClose: false,
      position: { top: '10%' },
    });

    let currentHeadersNames = [...this.headers, ...this.unsavedColumns];
    let newHeadersNames = [...this.headers.map(x => this.newPropertyNames[x]), ...this.unsavedColumns.map(x => this.newPropertyNames[x])];
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.columnName !== undefined) {
        if (currentHeadersNames.find(x => x == result.columnName) || newHeadersNames.find(x => x == result.columnName)) {
          this.notificationService.error('Column duplicated.');
          return;
        }

        Object.values(this.modifiedListData).forEach((sample: any) => {
          sample.data[result.columnName + ''] = '';
        });

        this.unsavedColumns.push(result.columnName);

        this.refreshPropertyPathsNamesAndDisplayedColumns();

        this.setListDataConfig();
      }
    });
  }

  removeColumn(colName: string) {
    for (let sample of this.listData.data) {
      delete sample.data[colName];
      delete this.modifiedListData[sample.id].data[colName];
    }

    this.unsavedColumns = this.unsavedColumns.filter(uc => uc != colName);

    this.refreshPropertyPathsNamesAndDisplayedColumns();

    this.setListDataConfig();
  }

  removeAnalyteColumn(colName: string) {
    for (let sample of this.listData.data) {
      if (sample.analytes) {
        sample.analytes.forEach((analyte: any) => {
          delete analyte.data[colName];
        });
      }
      if (this.modifiedListData[sample.id].analytes) {
        this.modifiedListData[sample.id].analytes.forEach((analyte: any) => {
          delete analyte.data[colName];
        });
      }
    }

    this.analyteUnsavedColumns = this.analyteUnsavedColumns.filter(uc => uc != colName);

    this.refreshPropertyPathsNamesAndDisplayedColumns();

    this.setListDataConfig();
  }

  duplicateRow(sampleItem: ISampleDisplayItem): void {
    this.appendNewRow(sampleItem);
  }

  removeRow(sampleItem: ISampleDisplayItem): void {
    delete this.modifiedListData[sampleItem.id];
    this.setNewListData(this.listData.data?.filter(x => x.id != sampleItem.id));
  }

  endEditMode(): void {
    this.isEditing.emit(false);
    this.isEditMode = false;
    this.postEditLoading = false;
    this.modifiedListData = {};

    this.unsavedColumns = [];
    this.analyteUnsavedColumns = [];

    this.refreshPropertyPathsNamesAndDisplayedColumns();
    this.resetHeadersChanges();

    const dataToKeep = this.listData.data.filter(d => d.id > 0);
    this.setNewListData(dataToKeep);

    setTimeout(() => {
      this.paginator!._changePageSize(this.prevPageSize);
      this.paginator!.firstPage();
    });

    this.ngOnInit();
  }

  showColumnAuditDialog() {
    this.dialogService.openChangelogDialog(
      `Change log for Samples`,
      this.experimentId,
      AuditObjectName.Samples,
      0,
      undefined,
      true
    );
  }

  showAuditDialog(sample: ISampleDisplayItem) {
    this.dialogService.openChangelogDialog(
      `Change log for Sample ${sample.label} - ${sample.name}`,
      this.experimentId,
      AuditObjectName.Samples,
      sample.sampleId
    );
  }

  private refreshPropertyPathsNamesAndDisplayedColumns() {
    this.propertyPaths = this.parsePropertyPaths();

    this.displayedColumns = this.propertyPaths;

    // these will be only the property names, without the 'data.' prefix
    this.propertyNames = [...this.headers, ...this.unsavedColumns]
    this.propertyNames.forEach((p: string) => {
      if (!this.newPropertyNames[p]) {
        this.newPropertyNames[p] = p;
      }
    });

    this.analytePropertyNames = [...this.analyteHeaders, ...this.analyteUnsavedColumns];
    this.analytePropertyNames.forEach((p: string) => {
      if (!this.analyteNewPropertyNames[p]) {
        this.analyteNewPropertyNames[p] = p;
      }
    });
  }

  private resetHeadersChanges() {
    this.newPropertyNames = {};
    this.propertyNames.forEach((p: string) => {
      this.newPropertyNames[p] = p;
    });
  }

  private parsePropertyPaths() {
    let propertyPaths = [
      'label',
      ...this.headers.map(x => `data.${x}`),
      ...this.unsavedColumns.map(x => `data.${x}`),
      'unevaluable',
      'actions',
    ];
    // Add fixed columns values
    propertyPaths.push(...this.staticColumns.map(x => x.prop));

    return propertyPaths;
  }

  private parsePropertyNames() {
    if (this.listData.data.length == 0) {
      return [];
    }

    let maxPropIndex = 0;
    let maxPropCount = 0;
    for (let i = 0; i < this.listData.data.length; i++) {
      const curPropCount = Object.keys(this.listData.data[i].data).length;
      if (maxPropCount < curPropCount) {
        maxPropCount = curPropCount;
        maxPropIndex = i;
      }
    }
    return Object.keys(this.listData.data[maxPropIndex].data);
  }

  private parseAnalytePropertyNames() {
    if (this.listData.data.length == 0) {
      return [];
    }
    let allAnaltytes: any[] = [];
    Object.values(this.listData.data).map(x => x.analytes).forEach(x => {
      if (x) {
        allAnaltytes.push(...x);
      }
    });

    if (allAnaltytes.length == 0) {
      return [];
    }

    return Object.keys(allAnaltytes[0].data);
  }

  private parseSamplesToSampleDisplayItems(samples: Sample[]): ISampleDisplayItem[] {
    const sampleDisplayItems: ISampleDisplayItem[] = [];
    for (let sample of samples) {
      let displayItem = <ISampleDisplayItem>{
        id: sample.sampleId,
        sampleId: sample.sampleId,
        label: sample.label,
        name: sample.name,
        unevaluable: sample.unevaluable,
        createdBy: sample.createdBy,
        dateCreated: sample.dateCreated,
        data: JSON.parse(sample.importData),
        analytes: sample.analytes.map((a: any) => {
          a.data = JSON.parse(a.importData);
          return a;
        })
      };
      // Add fixed columns values
      this.staticColumns.forEach((col) => {
        displayItem[col.prop] = (sample as any)[col.prop];
      });
      sampleDisplayItems.push(displayItem);
    }
    return sampleDisplayItems;
  }

  private setNewListData(data?: ISampleDisplayItem[]) {
    this.listData = new MatTableDataSource(data);
    this.parseSampleFromAPI();
    this.setListDataConfig();
    if (this.table) {
      this.table.renderRows();
    }
  }

  private setListDataConfig() {
    if (this.listData != null) {
      this.listData.sort = this.sort;
      this.listData.paginator = this.paginator;

      this.listData.filterPredicate = (data, filter) => {
        return this.displayedColumns.some(ele => {
          if (ele == 'actions') {
            return false;
          }

          let parts = ele.split('.');
          let columnData = undefined;

          if (parts.length > 1) {
            columnData = data.data[parts[1]].toString();
          } else {
            let prop = this.staticColumns.find(x => x.prop == ele);
            if (prop && prop.inputType === 'select') {
              columnData = prop.options.find((o: any) => o.value === data[ele]).label.toString();
            }
            else if (ele == 'unevaluable') {
            }
            else {
              columnData = data[ele].toString();
            }
          }
          return columnData != undefined && columnData.toLowerCase().indexOf(filter) != -1;
        }) || data.analytes?.some(analyte => {
          let columnData: any = undefined;
          return this.staticAnalyteColumns.some(prop => {
            // Check fixed columns
            if (prop && prop.inputType === 'select') {
              columnData = prop.options.find((o: any) => o.value === analyte[prop.prop]).label.toString();
            }
            else {
              columnData = analyte[prop.prop].toString();
            }
            return columnData != undefined && columnData.toLowerCase().indexOf(filter) != -1;
          }) || (analyte.data && Object.keys(analyte.data).some(prop => {
            // Check dynamic columns
            columnData = analyte.data[prop].toString();
            return columnData != undefined && columnData.toLowerCase().indexOf(filter) != -1;
          }));
        });
      };
    }
  }

  private async setSampleUnevaluableWithExceptionHandling(
    sample: Sample,
    batchReason: string,
    evtSource: MatCheckbox,
    prevValue: boolean
  ) {
    try {
      return await this.apiService.setSampleUnevaluableWithReasonAsync(sample, batchReason);
    } catch (err: any) {
      sample.unevaluable = !sample.unevaluable;
      evtSource.checked = prevValue;
      if (err.status == HttpStatusCode.BadRequest && err.error.expectedException) {
        this.notificationService.error(err.error.message);
      } else {
        throw err;
      }
      return;
    }
  }

  private deleteSample(sampleId: number, row: ISampleDisplayItem) {
    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `Sample ${row.label}`,
      field: 'Entire Row',
      oldValue: `Sample ${row.label}`,
      newValue: 'DELETED',
    });

    const relatedDetails = this.detailsService.getCurrentDetails().filter(d => d.sampleId == sampleId);
    if (relatedDetails && relatedDetails.length > 0) {
      changeSet.push({
        identifier: `Detail${relatedDetails.length > 1 ? 's' : ''}`,
        field: `${relatedDetails.length > 1 ? 'Multiple Rows' : 'Entire Row'}`,
        oldValue: `${relatedDetails.length} Detail Row${relatedDetails.length > 1 ? 's' : ''}`,
        newValue: `DELETED ${relatedDetails.length} related Detail${relatedDetails.length > 1 ? 's' : ''}`,
      });
    }

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      { isDelete: true }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        this.apiService.deleteSample(this.experimentId, sampleId, data.batchReason).subscribe(
          () => {
            const index = this.listData.data?.indexOf(row, 0);
            if (index != null && index > -1) {
              this.listData.data.splice(index, 1);
            }

            this.listData._updateChangeSubscription();
            this.appState.SetExperimentDataChanged(this.experimentId);

            this.notificationService.success('Sample deleted');

            const props: { [key: string]: string | number } = {
              ExperimentId: this.experimentId,
              DeletedSampleId: sampleId,
              SampleLabel: row.label,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_DELETE_SAMPLE, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }

  /**
   * Set the fixed columns based on the current experiment
   */
  setFixedColumns() {
    if (this.currentExperiment && (this.currentExperiment.studyType == StudyType.LoDConfirmation
      || this.currentExperiment.studyType == StudyType.LoDRangeFinding)) {
      this.staticColumns = Object.assign([], SampleColumns.lodColumns);
      this.staticAnalyteColumns = Object.assign([], AnalyteColumns.lodAnalyteColumns);
    }
    else if (this.currentExperiment && this.currentExperiment.studyType == StudyType.DailyExternalControls) {
      this.staticColumns = Object.assign([], SampleColumns.dailyExternalControlsColumns);
      this.staticAnalyteColumns = [];
    }
    else if (this.currentExperiment && this.currentExperiment.studyType == StudyType.General) {
      this.staticColumns = Object.assign([], SampleColumns.generalColumns);
      this.staticAnalyteColumns = Object.assign([], AnalyteColumns.generalAnalyteColumns);
    }
    else if (this.currentExperiment && this.currentExperiment.studyType == StudyType.Precision) {
      this.staticColumns = Object.assign([], SampleColumns.precisionColumns);
      this.staticAnalyteColumns = Object.assign([], AnalyteColumns.precisionAnalyteColumns);
    }
  }

  /**
   * Parse sample data from the table to send to the API
   * @param samples the samples to parse 
   */
  parseSampleToAPI(samples: Sample[]) {
    samples.forEach((sample: any) => {
      this.staticColumns.forEach((col) => {
        this.parseToAPI(sample, col);
      });
      if (sample.analytes) {
        sample.analytes.forEach((analyte: any) => {
          this.staticAnalyteColumns.forEach((col) => {
            this.parseToAPI(analyte, col);
          });
        });
      }
    });
  }

  /**
   * Parse obj from the API to display in the table  
   * @param obj   
   * @param col  
   */
  parseToAPI(obj: any, col: any) {
    if (col.isCustom && col.valueType === 'boolean') {
      if (obj[col.prop] === 'false') {
        obj[col.prop] = false;
      }
      if (obj[col.prop] === 'true') {
        obj[col.prop] = true;
      }
    }
    else if (col.isCustom && col.valueType === 'number') {
      if (obj[col.prop] != null) {
        obj[col.prop] = +obj[col.prop];
      }
    }

    if (col.prop === 'analyteTemplateId') {
      if (obj[col.prop] == 0) {
        obj[col.prop] = null;
      }
    }
  }

  /**
   * Parse sample data from API to display in the table
   */
  parseSampleFromAPI() {
    this.listData.data.forEach((sample: ISampleDisplayItem) => {
      this.staticColumns.forEach((col) => {
        this.parseFromAPI(sample, col);
      });
      if (sample.analytes) {
        sample.analytes.forEach((analyte: any) => {
          this.staticAnalyteColumns.forEach((col) => {
            this.parseFromAPI(analyte, col);
          });
        });
      }
    });
  }

  /**
   * Parse obj from the API to display in the table 
   * @param obj 
   * @param col 
   */
  parseFromAPI(obj: any, col: any) {
    if (col.isCustom && col.valueType === 'boolean') {
      if (obj[col.prop] === false) {
        obj[col.prop] = 'false';
      }
      if (obj[col.prop] === true) {
        obj[col.prop] = 'true';
      }
    }
    else if (col.isCustom && col.prop === 'analyteTemplateId') {
      if (obj[col.prop] == null) {
        obj[col.prop] = 0;
      }
    }
  }

  /**
   *  Get the paged data from the filtered data
   * @returns the paged data from the filtered data
   */
  getFilteredAndPagedData() {
    if (!this.listData || !this.paginator) return [];

    const filteredData = this.listData.filteredData;

    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;

    // Get the paged data from the filtered data
    const pagedData = filteredData.slice(startIndex, endIndex);

    return pagedData;
  }

  duplicateAnalyte(sampleId: number, analyte: any): void {
    this.appendNewAnalyte(sampleId, analyte);
  }

  removeAnalyte(sampleId: number, analyteIndex: number): void {
    this.modifiedListData[sampleId].analytes.splice(analyteIndex, 1);
    this.listData.data.find(x => x.id == sampleId)?.analytes.splice(analyteIndex, 1);
  }

  appendNewAnalyte(sampleId: number, analyte?: any) {
    let modifiedSample = this.modifiedListData[sampleId];
    let originalSample: any = this.listData.data.find(x => x.id == sampleId);

    // Validate if the sample has analytes
    if (!modifiedSample.analytes) {
      modifiedSample.analytes = [];
    }

    if (!originalSample.analytes) {
      originalSample.analytes = [];
    }

    // Validate maximum analytes per sample
    if (modifiedSample.analytes.length >= LabpartnerService.MAX_ANALYTES_PER_SAMPLE) {
      this.notificationService.warn('Maximum Analytes per Sample is ' + LabpartnerService.MAX_ANALYTES_PER_SAMPLE);
      return;
    }

    // Get all analytes
    let allAnalytes: any[] = [];
    Object.values(this.modifiedListData).forEach(x => {
      if (x.analytes) {
        allAnalytes.push(...x.analytes);
      }
    })
    allAnalytes.sort((a: any, b: any) => a.analyteId - b.analyteId);

    // Get the new analyte id
    let newId = -1;
    if (allAnalytes.length > 0) {
      newId = allAnalytes[0].analyteId < 0 ? allAnalytes[0].analyteId - 1 : -1;
    }

    // Get the newest analyte
    let lastAnalyte: any = allAnalytes[allAnalytes.length - 1];
    if (!lastAnalyte) {
      lastAnalyte = { data: {} } as any;
    }

    const newData: { [key: string]: any } = {};
    const newBlankData: { [key: string]: any } = {};
    for (let prop of Object.keys(lastAnalyte.data)) {
      newBlankData[prop] = '';
      if (analyte) {
        newData[prop] = analyte.data[prop];
      }
    }

    const newItem = <any>{
      analyteId: newId,
      name: analyte ? analyte.name : `NewAnalyte`,
      data: analyte ? newData : newBlankData
    };

    // Add fixed columns values
    this.staticAnalyteColumns.forEach((col) => {
      if (analyte) {
        newItem[col.prop] = analyte[col.prop];
      }
      else {
        if (col.valueType == 'number') {
          newItem[col.prop] = 0;
        } else {
          newItem[col.prop] = '';
        }
      }
    });

    if (!newItem.analyteTemplateId) {
      newItem.analyteTemplateId = 0;
    }

    modifiedSample.analytes.push({
      ...newItem,
      data: { ...(analyte ? newData : newBlankData) }
    });

    originalSample.analytes.push({
      ...newItem,
      data: { ...newBlankData },
    });

    setTimeout(() => {
      // Scroll to the new row
      const container = document.getElementById('samples-container');
      if (!container) return;
      const target = document.getElementById('analyte-' + newItem.analyteId);
      if (!target) return;
      console.log(container, target);
      target.scrollIntoView({
        behavior: 'smooth', // Enables smooth scrolling
        block: 'center',    // Aligns the element to the center of the container
        inline: 'nearest'   // Ensures it scrolls in the nearest direction
      });
    }, 250);
  }

  // add analyte column
  addAnalyteColumn(analyte: any) {
    if (Object.keys(analyte.data).length + this.analyteUnsavedColumns.length >= LabpartnerService.MAX_SAMPLES_AND_CONDITIONS_COLUMNS) {
      this.notificationService.warn('Maximum number of dynamic columns is ' + LabpartnerService.MAX_SAMPLES_AND_CONDITIONS_COLUMNS);
      return;
    }

    const dialogRef = this.matDialog.open<AddColumnDialogComponent, any, IAddColumnResult>(AddColumnDialogComponent, {
      width: '300px',
      panelClass: 'add-column-dialog-container',
      disableClose: false,
      position: { top: '10%' },
    });

    let currentHeadersNames = [...this.analyteHeaders, ...this.analyteUnsavedColumns];
    let newHeadersNames = [...this.analyteHeaders.map(x => this.analyteNewPropertyNames[x]), ...this.analyteUnsavedColumns.map(x => this.analyteNewPropertyNames[x])];
    dialogRef.afterClosed().subscribe(result => {
      if (result?.columnName) {
        if (currentHeadersNames.find(x => x == result.columnName) || newHeadersNames.find(x => x == result.columnName)) {
          this.notificationService.error('Analyte Column duplicated.');
          return;
        }
        this.analyteUnsavedColumns.push(result.columnName);

        Object.values(this.modifiedListData).forEach((sample: any) => {
          if (sample.analytes) {
            sample.analytes.forEach((analyte: any) => {
              analyte.data[result.columnName + ''] = '';
            });
          }
        });

        this.refreshPropertyPathsNamesAndDisplayedColumns();

        this.setListDataConfig();
      }
    });
  }

  // on delete analyte
  onDeleteAnalyte(sample: any, analyte: any) {
    const dialog = this.dialogService.openConfirmDialog(
      'Are you sure you want to delete this analyte?'
    );

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.deleteAnalyte(sample, analyte);
      }
    });
  }

  // delete analyte
  private deleteAnalyte(sample: ISampleDisplayItem, analyte: any) {
    let changeSet: IChangeSet[] = [];
    changeSet.push({
      identifier: `Sample ${sample.label}`,
      field: 'Analyte',
      oldValue: `Analyte ${analyte.name}`,
      newValue: 'DELETED',
    });

    const dialogRef = this.dialogService.openConfirmChangesDialog(
      changeSet,
      this.appState.GetCurrentExperiment().type,
      { isDelete: true }
    );

    dialogRef.afterClosed().subscribe(async data => {
      if (
        data?.submitClicked &&
        (data?.reasonProvided || this.appState.GetCurrentExperiment()?.type == ExperimentType.ResearchAndDevelopment)
      ) {
        this.apiService.deleteAnalyte(this.experimentId, analyte.analyteId, data.batchReason).subscribe(
          () => {
            const index = sample.analytes.indexOf(analyte, 0);
            if (index != null && index > -1) {
              sample.analytes.splice(index, 1);
              this.listData.data.find(x => x.id == sample.id)?.analytes.splice(index, 1);
            }

            this.listData._updateChangeSubscription();
            this.appState.SetExperimentDataChanged(this.experimentId);

            this.notificationService.success('Analyte deleted');

            const props: { [key: string]: string | number } = {
              ExperimentId: this.experimentId,
              DeletedAnalyte: analyte.analyteId,
              SampleId: sample.id,
              SampleLabel: sample.label,
              AnalyteName: analyte.name,
              DeviceType: this.appState.GetCurrentExperiment().deviceType,
              ExperimentOwner: this.currentExperiment?.createdBy ?? 0,
            };
            this.loggingService.logEvent(EVENT_DELETE_ANALYTE, props);
          },
          (err: any) => {
            const expectedErr = err.error as AuditedChangeResponse;
            if (expectedErr.expectedException) {
              this.notificationService.error(expectedErr.message);
            } else {
              throw err;
            }
          }
        );
      }
    });
  }

  // Get the value of the selected option
  getSelectValue(col: any, obj: any) {
    return col.options.find((o: any) => o.value == obj[col.prop])?.label;
  }

  // Get the value of the selected option
  onChangeAnalyteName(analyteCol: any, analyte: any) {
    if (analyteCol.prop == 'analyteTemplateId' && (this.currentExperiment?.studyType == StudyType.LoDConfirmation
      || this.currentExperiment?.studyType == StudyType.LoDRangeFinding
      || this.currentExperiment?.studyType == StudyType.General)) {
      if (analyte.analyteTemplateId == 0) {
        for (let col of this.staticAnalyteColumns) {
          if (!col.isCustom) {
            analyte[col.prop] = '';
          }
          if (col.valueType == 'number') {
            analyte[col.prop] = 0;
          }
        }
      }
    }
  }

  // Convert number to scientific notation
  toScientificNotation(num: number) {
    if (!num) return 0;
    const exponent = Math.floor(Math.log10(Math.abs(num)));
    const mantissa = num / Math.pow(10, exponent);
    return `${mantissa.toFixed(6)}e${exponent}`;
  }

  // Check if should show analytes
  shouldShowAnalytes(): boolean {
    return this.currentExperiment?.studyType == StudyType.LoDConfirmation ||
      this.currentExperiment?.studyType == StudyType.LoDRangeFinding ||
      this.currentExperiment?.studyType == StudyType.General ||
      this.currentExperiment?.studyType == StudyType.Precision;
  }

  // Check if the analyte column is invalid
  isAnalyteColumnInvalid(analyteCol: any, sample: any, analyteRowIndex: number) {
    let modifiedSample = this.modifiedListData[sample.id];
    let modifiedAnalyte = modifiedSample.analytes[analyteRowIndex];

    // Validate the analyte name is not duplicated
    if (analyteCol.prop === 'analyteTemplateId') {
      let analytesIds = modifiedSample.analytes.map((a: any) => a.analyteTemplateId).filter((a: any) => a == modifiedAnalyte.analyteTemplateId);
      if (analytesIds.length > 1) {
        return true;
      }
    }

    if (analyteCol.inputType == 'number' && analyteCol.minValue !== undefined) {
      if (+modifiedAnalyte[analyteCol.prop] < analyteCol.minValue) {
        return true;
      }
    }
    // Validate all the required columns  
    else if (analyteCol.required &&
      !modifiedAnalyte[analyteCol.prop] &&
      modifiedAnalyte.analyteTemplateId != 0) {
      return true;
    }

    return false;
  }
}
