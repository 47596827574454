import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DetailsResultsComponent } from 'src/app/results/details-results/details-results.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import { DetailAndSavannaResult } from 'src/app/services/labpartner.service.model';
import { LoggingService } from 'src/app/services/logging.service';
import { NotesService } from 'src/app/services/notes-service.service';
import { SavannaConfigService } from 'src/app/services/savanna-config.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { ExperimentService } from 'src/app/shared/experiment.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
    selector: 'app-fix-duplicate-results',
    templateUrl: './fix-duplicate-results.component.html',
    styleUrls: ['./fix-duplicate-results.component.scss']
})
export class FixDuplicateResultsComponent extends DetailsResultsComponent implements OnInit {
    isLoading = true;
    backdrop: any;
    combinedDetailsAndResultsWithDuplicates: any[] = [];

    constructor(
        protected apiService: LabpartnerService,
        public accountService: UserAccountService,
        protected notificationService: NotificationService,
        protected savannaConfigService: SavannaConfigService,
        protected loggingService: LoggingService,
        protected dialog: MatDialog,
        protected appState: AppStateService,
        protected experimentService: ExperimentService,
        protected userAccountService: UserAccountService,
        public notesService: NotesService,
        public dialogRef: MatDialogRef<FixDuplicateResultsComponent>,
        protected cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(apiService, accountService, notificationService, savannaConfigService, loggingService, dialog, appState, experimentService, userAccountService, notesService, cdr);
        this.defaultTableColumns = this.defaultTableColumns.filter(x => x.field != 'notes');
    }

    ngOnInit(): void {
        this.backdrop = document.querySelector(`.dynamic-dialog-backdrop`);
        if (this.backdrop) {
            this.backdrop.style.display = 'none';
        }
        this.currentExperimentId = this.data.experimentId;
        this.clearAndLoadExperiment(false).subscribe(() => {
            this.isLoading = false;
            // If there are no details or results, close the dialog
            if (this.combinedDetailsAndResultsFiltered.length === 0 || !this.resultsAndConfig || this.resultsAndConfig.results.length == 0) {
                this.dialogRef.close({
                    status: false
                });
                return;
            }

            // If there are no duplicate results, close the dialog
            if (this.combinedDetailsAndResultsFiltered.filter(x => x.hasDuplicateResults == true).length === 0) {
                this.dialogRef.close({
                    status: true,
                    userSelection: []
                });
                return;
            }
            if (this.backdrop) {
                this.backdrop.style.display = 'block';
            }

            // Get the details and results with duplicates
            this.combinedDetailsAndResultsWithDuplicates = JSON.parse(JSON.stringify(this.combinedDetailsAndResultsFiltered.filter(x => x.hasDuplicateResults == true)));
            // Select the first result for each detail
            this.combinedDetailsAndResultsWithDuplicates.forEach(x => {
                var results = [];
                if (x.result) {
                    x.result.isSelected = true;
                    // Add the first result to the other results
                    results.push(x.result);
                }
                results = results.concat(x.otherResults);
                x.otherResults = results;
            });
        }, () => {
            this.isLoading = false;
        });
    }

    // When a result is selected, deselect all other results
    onSelectionChanged(detail: any, result: any) {
        detail.otherResults.filter((x: any) => x !== result).forEach((x: any) => x.isSelected = false);
        result.isSelected = true;
    }

    // Continue with the selected results
    continue() {
        this.dialogRef.close({
            status: true,
            userSelection: this.combinedDetailsAndResultsWithDuplicates.map(x => {
                return {
                    sampleId: x.detail.barcodeID,
                    testResultId: x.otherResults.find((y: any) => y.isSelected).testResultId
                }
            })
        });
    }

    // Check if there are duplicate results
    public hasDuplicateResults() {
        return this.combinedDetailsAndResultsFiltered.filter(x => x.hasDuplicateResults == true).length > 0;
    }
}