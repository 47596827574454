<!-- Title Section -->
<div class="title">
    <span>Manage Assays</span>
</div>
<!-- Header Actions Section -->
<div class="actions">
    <button mat-raised-button color="light" (click)="addAssay()">
        <mat-icon>add</mat-icon>
        Add Assay
    </button>
    <div class="separator"></div>
    <mat-slide-toggle color="primary" [(ngModel)]="showArchivedAssays" (change)="setAssays()">
        <span>Show archived assays</span>
    </mat-slide-toggle>
</div>
<!-- Table Container -->
<div class="table-container mat-elevation-z1">
    <table mat-table #table [dataSource]="dataSource">
        <!-- Assay ID -->
        <ng-container matColumnDef="assayId">
            <th mat-header-cell *matHeaderCellDef> Assay ID </th>
            <td mat-cell *matCellDef="let row">
                {{row.assayId}}
                <button *ngIf="!row.isActive" mat-icon-button (click)="unarchiveAssay(row)"
                    matTooltip="Unarchive the Assay"
                    [disabled]="!(currentRoles | hasAnyRoles : ['ADMIN', 'WRITER'] : null : 'IsAnyStatus')">                  
                    <mat-icon>unarchive</mat-icon>
                </button>
            </td>
        </ng-container>
        <!-- Assay Revision -->
        <ng-container matColumnDef="assayRevision">
            <th mat-header-cell *matHeaderCellDef> Assay Revision </th>
            <td mat-cell *matCellDef="let row"> {{row.assayRevision}} </td>
        </ng-container>
        <!-- Assay Name -->
        <ng-container matColumnDef="assayName">
            <th mat-header-cell *matHeaderCellDef> Assay Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>
        <!-- Assay Short Name -->
        <ng-container matColumnDef="assayShortName">
            <th mat-header-cell *matHeaderCellDef> Assay Short Name </th>
            <td mat-cell *matCellDef="let row"> {{row.shortName}} </td>
        </ng-container>
        <!-- Analytes Names -->
        <ng-container matColumnDef="analyteName">
            <th mat-header-cell *matHeaderCellDef> Analyte Name </th>
            <td mat-cell *matCellDef="let row">
                <div class="analytes-container">
                    <div *ngFor="let analyte of row.analyteTemplates">{{analyte.name}}</div>
                </div>
            </td>
        </ng-container>
        <!-- Analytes Short Names -->
        <ng-container matColumnDef="analyteShortName">
            <th mat-header-cell *matHeaderCellDef> Analyte Short Name </th>
            <td mat-cell *matCellDef="let row">
                <div class="analytes-container">
                    <div *ngFor="let analyte of row.analyteTemplates">{{analyte.shortName}}</div>
                </div>
            </td>
        </ng-container>
        <!-- Analytes chambers -->
        <ng-container matColumnDef="chamber">
            <th mat-header-cell *matHeaderCellDef> Chamber </th>
            <td mat-cell *matCellDef="let row">
                <div class="analytes-container">
                    <div *ngFor="let analyte of row.analyteTemplates">{{analyte.chamber}}</div>
                </div>
            </td>
        </ng-container>
        <!-- Analytes Channels -->
        <ng-container matColumnDef="channel">
            <th mat-header-cell *matHeaderCellDef> Channel </th>
            <td mat-cell *matCellDef="let row">
                <div class="analytes-container">
                    <div *ngFor="let analyte of row.analyteTemplates">{{analyte.channel}}</div>
                </div>
            </td>
        </ng-container>
        <!-- Analytes GroupId -->
        <ng-container matColumnDef="groupId">
            <th mat-header-cell *matHeaderCellDef> GroupId </th>
            <td mat-cell *matCellDef="let row">
                <div class="analytes-container">
                    <div *ngFor="let analyte of row.analyteTemplates">
                        <span *ngIf="analyte.groupId">{{analyte.groupId}}</span>
                        <span *ngIf="!analyte.groupId">&nbsp;</span>
                    </div>
                </div>
            </td>
        </ng-container>
        <!-- Assay Instrument -->
        <ng-container matColumnDef="instrument">
            <th mat-header-cell *matHeaderCellDef> Instrument </th>
            <td mat-cell *matCellDef="let row"> {{row.instrument}} </td>
        </ng-container>
        <!-- Assay Actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button *ngIf="row.isActive" mat-icon-button (click)="archiveAssay(row)"
                    matTooltip="Archive the Assay"
                    [disabled]="!(currentRoles | hasAnyRoles : ['ADMIN', 'WRITER'] : null : 'IsAnyStatus')">
                    <mat-icon>archive</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<!-- Footer Actions  -->
<div class="footer mat-elevation-z1">
    <div class="separator"></div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>