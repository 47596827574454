<div *ngIf="experiment$ | async as experiment">
  <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="selectedTabChanged($event)">
    <mat-tab label="Inputs" [disabled]="appInputs.childIsInEditMode">
      <app-inputs #appInputs [experimentId]="experiment.experimentId" [currentExperiment]="currentExperiment"
        [currentRoles]="currentRoles" [inputSelected]="inputSelected" [auditCountMap]="auditCountMap"></app-inputs>
    </mat-tab>
    <mat-tab label="Logistics" [disabled]="appInputs.childIsInEditMode">
      <app-logistic-list [experimentId]="experiment.experimentId" [currentExperiment]="currentExperiment"
        [currentRoles]="currentRoles" [logisticsAuditCountMap]="auditCountMap?.Logistics"></app-logistic-list>
    </mat-tab>
    <mat-tab label="Uploads" [disabled]="appInputs.childIsInEditMode">
      <app-file-upload [experimentId]="experiment.experimentId" [currentExperiment]="currentExperiment"
        [currentRoles]="currentRoles">
      </app-file-upload>
    </mat-tab>
    <mat-tab label="Details" [disabled]="appInputs.childIsInEditMode">
      <app-detail-list [experimentId]="experiment.experimentId" [currentExperiment]="currentExperiment"
        [currentRoles]="currentRoles" [detailsNotesCountMap]="(notesService.experimentNotesCountMap | async)?.Detail"
        [detailsLiveNotesCountMap]="(notesService.experimentNotesCountMap | async)?.DetailLiveNote"
        [detailsAuditCountMap]="auditCountMap?.Details"></app-detail-list>
    </mat-tab>
    <mat-tab
      *ngIf="experiment.studyType === StudyType.LoDRangeFinding || experiment.studyType === StudyType.LoDConfirmation || experiment.studyType === StudyType.Precision"
      label="Data Insights Preview" [disabled]="appInputs.childIsInEditMode || !(currentRoles | hasAnyRoles : ['WRITER'] : currentExperiment : 'IsGreaterThanDetailsGenerated')">
      <app-automated-reports *ngIf="currentTabName === 'Data Insights Preview'" [experimentId]="experiment.experimentId">
      </app-automated-reports>
    </mat-tab>
  </mat-tab-group>
</div>