import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LabpartnerService } from '../services/labpartner.service';

export class HttpRequestCountInterceptor implements HttpInterceptor {
  private debugMode = false;

  constructor(private _apiService: LabpartnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // prevent button flicker on experiment details with fusion api call
    if (request.headers.get('X-Hide-Loader') === 'true') {
      return next.handle(request);
    }

    this._apiService.incrementHttpRequestCount(this.debugMode ? request.url : undefined);
    return next
      .handle(request)
      .pipe(finalize(() => this._apiService.decrementHttpRequestCount(this.debugMode ? request.url : undefined)));
  }
}
