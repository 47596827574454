import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { LabpartnerService } from '../services/labpartner.service';
import { Experiment, StudyType } from '../services/labpartner.service.model';
import { ExperimentService } from '../shared/experiment.service';
import { BaseComponent } from '../support/base.component';

import { LoggingService } from '../services/logging.service';
import { EVENT_OPEN_EXPERIMENT, TAB_PAGE_NAMES } from '../services/logging-constants';
import { AppStateService } from '../services/app-state.service';
import { AuditService } from '../services/audit.service';
import type { AuditCountMap } from '../services/audit.service.models';
import { NotesService } from '../services/notes-service.service';
import { takeUntil } from 'rxjs/operators';
import { UserAccountService } from '../services/user-account.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-experiment-detail',
  templateUrl: './experiment-detail.component.html',
  styleUrls: ['./experiment-detail.component.scss']
})
export class ExperimentDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  auditCountMap?: AuditCountMap;

  experiment$!: Observable<Experiment>;
  selectedIndex: number = 0;
  inputSelected: boolean = false;

  currentExperiment: Experiment | null = null;
  currentRoles: string[] = [];

  StudyType = StudyType;

  currentTabName: string = "";

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public service: ExperimentService,
    public accountService: UserAccountService,
    public apiService: LabpartnerService,
    public notesService: NotesService,
    private auditService: AuditService,
    private route: ActivatedRoute,
    private appState: AppStateService,
    private loggingService: LoggingService,
  ) {
    super();
  }

  protected ngOnDestroyInternal(): void { }

  ngOnInit() {
    const id = this.route.snapshot.params['id'] as number;
    const tab = this.route.snapshot.params['tab'] as number;
    this.selectedIndex = tab;
    this.experiment$ = this.apiService.getExperiment(id);

    this.appState.ExperimentOpened.pipe(takeUntil(this._destroying$)).subscribe(e => {
      this.currentExperiment = e;
    });

    this.accountService.currentRoles$.pipe(takeUntil(this._destroying$)).subscribe(roles => {
      this.currentRoles = roles;
    });

    this.subscription.add(this.auditService.auditCountMap.subscribe(acm => {
      this.auditCountMap = acm;
    }));

    this.subscription.add(this.experiment$.subscribe(exp => {
      this.appState.SetCurrentExperiment(exp);
      const props: { [key: string]: number } = {
        ExperimentId: exp.experimentId,
        DeviceType: exp.deviceType
      };
      this.loggingService.logEvent(EVENT_OPEN_EXPERIMENT, props);
    }));

    // Input is now considered 5.
    this.trackPageView(5);
  }

  selectedTabChanged(event: MatTabChangeEvent) {
    this.currentTabName = event.tab.textLabel;
    if (event.index < 0 || event.index > 3) {
      console.error('unexpected tab index');
      return;
    }

    if (event.index == 0) {
      this.inputSelected = true;
    } else {
      this.inputSelected = false;
    }

    // Accommodate tracking for tab switching, essentially maintain Logistics as 2, Uploads as 3, and Details as 4
    // Sub to Input, Sample is 0 and Conditions is 1. Input is now considered 5.
    this.trackPageView(this.inputSelected ? 5 : event.index + 1);
  }

  private trackPageView(pageIndex: number) {
    const pageName = TAB_PAGE_NAMES[pageIndex];
    this.loggingService.logPageView(pageName);
  }
}
