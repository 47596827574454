import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SortEvent } from 'primeng/api';
import { FrozenColumn, Table } from 'primeng/table';
import { EMPTY, Observable, Subject, forkJoin, of } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IDetailDisplayItem } from 'src/app/detail/detail-list/detail-list.component';
import { DeviceTargetErrorDialogComponent, DeviceTargetErrorDialogData, DeviceTargetErrorDialogResult } from 'src/app/device-target-error-dialog/device-target-error-dialog.component';
import { InstrumentErrorDialogData, InstrumentErrorDialogComponent, InstrumentErrorDialogResult } from 'src/app/instrument-error-dialog/instrument-error-dialog.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { LabpartnerService } from 'src/app/services/labpartner.service';
import {
  Detail,
  DetailAndSavannaResult,
  DetailGroupedBySampleAndCondition,
  DeviceTargetError,
  Experiment,
  InstrumentError,
  SavannaAssayLayout,
  SavannaResult,
  SavannaResultsAndConfig,
  SavannaTarget,
  VerbedExperimentStatus,
} from 'src/app/services/labpartner.service.model';
import {
  EVENT_RESET_TABLE_COLUMNS,
  EVENT_SAVANNARESULTS,
  EVENT_SAVANNARESULTS_FOUND_DUPLICATES,
  EVENT_SAVANNA_DOWNLOAD_CSV,
} from 'src/app/services/logging-constants';
import { LoggingService } from 'src/app/services/logging.service';
import { NotesService } from 'src/app/services/notes-service.service';
import { SavannaConfigService } from 'src/app/services/savanna-config.service';
import { UserAccountService } from 'src/app/services/user-account.service';
import { ExperimentService } from 'src/app/shared/experiment.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { BaseComponent } from 'src/app/support/base.component';
import * as XLSX from 'xlsx-js-style';
export interface IColumnDefinition {
  initialIndex: number;
  parentField: string;
  field: string;
  header: string;
  visible: boolean;
}

export interface ColumnTotal {
  columnName: string;
  shortName: string;
  Positive: 0,
  Negative: 0,
  Invalid: 0,
  NotEvaluableEmpty: 0,
  NotEvaluablePositive: 0,
  NotEvaluableNegative: 0,
  NotEvaluableInvalid: 0,
  savannaTarget: SavannaTarget
}
@Component({
  selector: 'app-details-results',
  templateUrl: './details-results.component.html',
  styleUrls: ['./details-results.component.scss'],
})
export class DetailsResultsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentExperimentId: number = 0;
  @Input() currentExperimentOwner: number = 0;

  detailsNotesCountMap: any = null;

  detailsLiveNotesCountMap: any = null;

  currentExperiment: Experiment | null = null;

  details: IDetailDisplayItem[] = [];

  instrumentErrors: InstrumentError[] = [];

  targetErrors: DeviceTargetError[] = [];

  VerbedExperimentStatus = VerbedExperimentStatus;

  currentRoles: string[] = [];

  @ViewChild(Table) primeTable!: Table;

  @ViewChildren(FrozenColumn) frozenColumnsQl!: QueryList<FrozenColumn>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.refreshFrozenRowStyles();
  }

  hideEmptyResults = false;

  searchKey: string = '';

  tableData: DetailAndSavannaResult[] = [];

  defaultTableColumns: IColumnDefinition[] = [
    { initialIndex: 0, parentField: 'detail', field: 'sampleName', header: 'Sample', visible: true },
    { initialIndex: 1, parentField: 'detail', field: 'conditionName', header: 'Condition', visible: true },
    { initialIndex: 2, parentField: 'detail', field: 'barcodeID', header: 'Barcode / Sample ID', visible: true },
    { initialIndex: 3, parentField: 'result', field: 'instrumentId', header: 'Instrument ID', visible: true },
    { initialIndex: 4, parentField: 'result', field: 'assayFilename', header: 'AMF', visible: true },
    { initialIndex: 5, parentField: 'result', field: 'operatorName', header: 'Operator Name', visible: true },
    { initialIndex: 6, parentField: 'result', field: 'timestamp', header: 'Run Date', visible: true },
    { initialIndex: 7, parentField: 'detail', field: 'instrumentError', header: 'Inst Error', visible: true },
    { initialIndex: 8, parentField: 'detail', field: 'unevaluable', header: 'Not Eval', visible: true },
    { initialIndex: 9, parentField: 'detail', field: 'notes', header: 'Notes', visible: true }

  ];

  tableColumns: IColumnDefinition[] = [];

  frozenMode = true;
  isLoading = false;

  combinedDetailsAndResultsGrouped: DetailGroupedBySampleAndCondition[] = [];

  combinedDetailsAndResults!: DetailAndSavannaResult[];
  combinedDetailsAndResultsFiltered!: DetailAndSavannaResult[];
  // dictionary (analyte name) of dictionaries (result) with result target LongName = [POSITIVE|NEGATIVE|INVALID] count
  columnCounts: { [key: string]: { [key: string]: number } } = {};

  staticColumns = [
    'BarcodeID',
    'Sample Tube ID',
    'Savanna Cartridge Lot',
    'Savanna Cartridge Serial',
    'Savanna Instrument ID',
    'Savanna Comments',
    'Savanna Evaluable',
    'Savanna Reason for Not Evaluable',
    'SampleName',
    'ConditionName',
    'AssayFilename',
    'OperatorName',
    'Timestamp',
    'InstrumentError'
  ];
  dynamicColumns: string[] = [];

  visibleColumns: IColumnDefinition[] = this.tableColumns;

  get selectedColumns(): IColumnDefinition[] {
    return this.visibleColumns;
  }

  set selectedColumns(val: IColumnDefinition[]) {
    const visibleFields = val.map(x => x.field);
    this.tableColumns.forEach(tableCol => {
      if (visibleFields.includes(tableCol.field)) {
        tableCol.visible = true;
      } else {
        tableCol.visible = false;
      }
    });
    this.visibleColumns = this.tableColumns.filter(x => x.visible);
    this.cdr.detectChanges();
  }

  get columnVisibilityOptions() {
    return this.primeTable?.columns?.concat(this.tableColumns.filter(x => !x.visible)) ?? [];
  }

  get tableStateKey() {
    return `UserId${this.apiService.getLoggedInUser()}_AssayId${this.selectedAssayIdRevision?.assayId}Rev${this.selectedAssayIdRevision?.assayRevision
      }`;
  }

  assayIdRevisionGroups: { value: SavannaAssayLayout; viewValue: string }[] = [];
  selectedAssayIdRevision?: SavannaAssayLayout;

  resultsAndConfig!: SavannaResultsAndConfig;

  readonly RESULT_POSITIVE = 'Positive';
  readonly RESULT_NEGATIVE = 'Negative';
  readonly RESULT_INVALID = 'Invalid';
  readonly RESULT_UNEVALUABLE = 'NotEvaluable';

  private readonly _destroying$ = new Subject<void>();

  columnTotals: ColumnTotal[] = [];

  @Output() onResultsLoaded = new EventEmitter<{ data: DetailGroupedBySampleAndCondition[], dynamicColumns: string[] }>();

  constructor(
    protected apiService: LabpartnerService,
    public accountService: UserAccountService,
    protected notificationService: NotificationService,
    protected savannaConfigService: SavannaConfigService,
    protected loggingService: LoggingService,
    protected dialog: MatDialog,
    protected appState: AppStateService,
    protected experimentService: ExperimentService,
    protected userAccountService: UserAccountService,
    public notesService: NotesService,
    protected cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.userAccountService.currentRoles$.subscribe(roles => {
        this.currentRoles = roles;
      }));

    this.subscription.add(
      this.notesService.experimentNotesCountMap.subscribe((experimentNoteCounts) => {
        this.detailsNotesCountMap = experimentNoteCounts.Detail;
        this.detailsLiveNotesCountMap = experimentNoteCounts.DetailLiveNote;
      })
    )
    this.savannaConfigService.configUpdated$.pipe(takeUntil(this._destroying$)).subscribe(configUpdated => {
      if (configUpdated && this.currentExperimentId > 0) {
        this.refreshResults();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onResultsLoaded.emit({ data: [], dynamicColumns: [] });
    this.clearAndLoadExperiment(false).subscribe(() => { });
  }

  protected ngOnDestroyInternal(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async loadNotes() {
    return this.apiService
      .getNoteCountsByExperimentId(this.currentExperimentId)
      .subscribe(experimentNoteCounts => {
        this.notesService.experimentNotesCountMap.next(experimentNoteCounts);
      })
  }

  onColumnReorder(evt: any) {
    const rightColumnsStartIdx = evt.columns.filter(
      (x: any) => x.parentField == 'detail' || x.parentField == 'result'
    ).length;

    // If dragging a result out of results - or - dragging a static column into results...
    if (
      (evt.dragIndex >= rightColumnsStartIdx && evt.dropIndex < rightColumnsStartIdx) ||
      (evt.dragIndex < rightColumnsStartIdx && evt.dropIndex >= rightColumnsStartIdx)
    ) {
      if (evt.dragIndex > evt.dropIndex) {
        // columns before drop location + columns after dropped column's new location up to where it was dragged from + dragged column + remaining columns
        evt.columns = evt.columns
          .slice(0, evt.dropIndex)
          .concat(evt.columns.slice(evt.dropIndex + 1, evt.dragIndex + 1))
          .concat([evt.columns[evt.dropIndex]])
          .concat(evt.columns.slice(evt.dragIndex + 1));
      } else {
        // columns before dragged column + dragged column + columns after until dropped location + columns after dropped location
        evt.columns = evt.columns
          .slice(0, evt.dragIndex)
          .concat([evt.columns[evt.dropIndex]])
          .concat(evt.columns.slice(evt.dragIndex, evt.dropIndex))
          .concat(evt.columns.slice(evt.dropIndex + 1));
      }

      this.selectedColumns = evt.columns;
    }

    let newTableColumns: IColumnDefinition[] = [];
    // At this point in time, selectedColumns are correctly ordered but missing hidden columns
    // Take each selectedColumn in order and add it to a new collection
    this.selectedColumns.forEach(colField => {
      const tableCol = this.tableColumns.find(x => x.field == colField.field);
      if (tableCol) {
        newTableColumns.push(tableCol);
      }
    });

    // Then take each hidden column and add it to its guesstimated position
    const unorderedColumns = this.tableColumns.filter(x => !x.visible);
    unorderedColumns.forEach(unorderedColumn => {
      newTableColumns = newTableColumns
        .slice(0, unorderedColumn.initialIndex)
        .concat([unorderedColumn])
        .concat(newTableColumns.slice(unorderedColumn.initialIndex));
    });

    // Set the base table columns to the ordered state so that selected & table are in sync
    this.tableColumns = newTableColumns;

    this.refreshFrozenRowStyles();
    this.saveTableState();
  }

  onColumnResize(evt: any) {
    this.refreshFrozenRowStyles();
    this.saveTableState();
  }

  onColumnSelectionShow(evt: any) {
    // This helps trigger auto-sizing of columns since we're using frozen + sticky + resizable
    this.frozenMode = false;
  }

  onColumnSelectionChange(evt: any) {
    if (!evt || !evt.itemValue) {
      return;
    }

    // If a column is being shown, we don't remove its column width, but we do try and calculate a standard and add it
    if (evt.itemValue.visible) {
      // Force the table view state to update
      this.primeTable.cd.detectChanges();
      this.updateWidthOfNewlyVisibleColumn(evt.itemValue.initialIndex);
      return;
    }

    const tableConfig = window.sessionStorage.getItem(`${this.tableStateKey}`);
    if (!tableConfig) {
      return;
    }

    const columnWidthsConfig = tableConfig ? (JSON.parse(tableConfig) as { columnWidths: string }).columnWidths : '';
    if (!columnWidthsConfig) {
      return;
    }

    const columnWidths = columnWidthsConfig.split(',');
    this.primeTable.columnWidthsState = columnWidths
      .slice(0, evt.itemValue.initialIndex)
      .concat(columnWidths.slice(evt.itemValue.initialIndex + 1))
      .join(',');

    this.primeTable.saveState();
    this.primeTable.restoreColumnWidths();
  }

  onColumnSelectionHide(evt: any) {
    // This restores frozen columns after column selections are made
    this.frozenMode = true;
    this.saveTableState();
  }

  customSort(evt: SortEvent) {
    const field = evt.field ?? '';
    const order = evt.order ?? 1;

    this.tableData.sort((a, b) => {
      const value1 = this.tableSortDataAccessor(a, field);
      const value2 = this.tableSortDataAccessor(b, field);

      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return order * result;
    });

    this.refreshFrozenRowStyles();
  }

  paginate(evt: any) {
    this.refreshFrozenRowStyles();
  }

  resetTable() {
    const props: { [key: string]: number | string } = {
      ExperimentId: this.currentExperimentId,
      ExperimentOwner: this.currentExperimentOwner,
      Table: 'SavannaResults',
      ClearAllUsed: 0,
    };

    if (this.searchKey == '**CLEAR ALL**') {
      Object.keys(window.sessionStorage)
        .filter(x => x.startsWith('UserId'))
        .forEach(x => window.sessionStorage.removeItem(x));
      props.ClearAllUsed = 1;
    }

    this.loggingService.logEvent(EVENT_RESET_TABLE_COLUMNS, props);

    window.sessionStorage.removeItem(`${this.tableStateKey}_VisibleColumns`);

    this.setTableStateKey();

    // Clear any filters, sorting, and reset to first page + clear session-stored state
    this.primeTable.clear();
    this.primeTable.clearState();

    // Reset column ordering
    this.tableColumns.forEach((col, idx) => {
      col.visible = true;
      this.updateWidthOfNewlyVisibleColumn(idx);
    });
    this.updateWidthOfNewlyVisibleColumn(9, true);

    this.tableColumns = JSON.parse(JSON.stringify(this.tableColumns.sort((a, b) => a.initialIndex - b.initialIndex)));

    this.selectedColumns = this.tableColumns;

    // Reinitialize data and dynamic columns
    this.initializeColumns();

    setTimeout(() => {
      this.primeTable.destroyStyleElement();
      this.primeTable.createStyleElement();
      this.refreshFrozenRowStyles();
    });
  }

  refreshResults() {
    this.clearAndLoadExperiment(true).subscribe(() => { });
  }

  clearAndLoadExperiment(preserveSearch: boolean) {
    this.selectedColumns = this.tableColumns;
    this.tableData = [];
    this.combinedDetailsAndResults = [];
    this.combinedDetailsAndResultsFiltered = [];

    if (this.currentExperimentId != 0) {
      return this.loadCurrentExperiment(preserveSearch);
    }
    else {
      return of(this.resultsAndConfig);
    }
  }

  loadCurrentExperiment(preserveSearch: boolean): Observable<any> {
    console.log(`fetching savanna results for exp ${this.currentExperimentId}`);

    if (!preserveSearch) {
      this.onSearchClear();
    }

    // we used to go get LabPartner details and the Savanna Results simultaneously (with forkjoin)
    // but now since we need the details to see what barcode strings were used, we'll do that first
    // then when it comes back we go get SavannaResults based on the barcode string prefix
    return forkJoin([
      this.apiService.getDetailsList('experimentId', this.currentExperimentId),
      this.apiService.getDeviceTargetErrorsByExperimentId(this.currentExperimentId),
      this.apiService.getInstrumentErrorsByExperimentId(this.currentExperimentId),
      this.apiService.getExperiment(this.currentExperimentId),
      this.loadNotes()
    ]).pipe(
      switchMap(results => {
        // was going to get all the prefixes for the whole collection and then distinct to make sure they're all the same
        // then decided that was overkill, they are all the same by design (we programmatically build them that way)
        // so we'll just grab the first one
        this.currentExperiment = results[3];
        this.details = results[0];
        this.targetErrors = results[1].filter(x => x.isActive);
        this.instrumentErrors = results[2].filter(x => x.isActive);

        return this.processExperiment(preserveSearch);
      })
    );
  }

  processExperiment(preserveSearch: boolean): Observable<SavannaResultsAndConfig> {
    if (this.details.length == 0) {
      this.notificationService.warn('No details records found for this experiment.');

      // still merge just to clear out any previous results
      this.mergeDetailsAndResults(this.details, this.targetErrors, this.instrumentErrors, undefined);

      if (preserveSearch) {
        // refresh the search filter based on preserved search key
        this.onSearchChanged(this.searchKey);
      }
      this.cdr.detectChanges();
      return of(this.resultsAndConfig);
    }

    // we're guaranteed there is at least one, from above, and since we generated the barcode IDs, we know
    // the format, and want the first section before the _ char
    let groups = this.details[0].barcodeID.split('_');
    let firstPrefix = '';
    if (groups.length == 1) {
      // we must have legacy barcode (looks like this: E0126S01C01R01)
      // only grab the first 5 chars
      firstPrefix = groups[0].slice(0, 5);
    } else {
      firstPrefix = groups[0];
    }

    return this.getSavannaResultsAndParseData(firstPrefix, this.details, this.targetErrors, this.instrumentErrors, preserveSearch);
  }

  onSearchClear() {
    this.searchKey = '';
    this.onSearchChanged('');
  }

  onSearchChanged(newVal: string) {
    this.applyFilterHideEmptyResults(this.searchKey.trim().toLowerCase());

    this.filterChangedUpdateCounts();
  }

  hideResultsChanged() {
    this.applyFilterHideEmptyResults();
    this.primeTable.first = 0;
    this.frozenColumnsQl.forEach(fc => fc.updateStickyPosition());
    this.cdr.detectChanges();
  }

  initializeColumns(assayChangeTrigger: boolean = false) {
    this.dynamicColumns = [];

    const resultsToDisplay = this.combinedDetailsAndResults.filter(
      cdr =>
        cdr.result?.assayId == this.selectedAssayIdRevision?.assayId &&
        cdr.result?.assayRevision == this.selectedAssayIdRevision?.assayRevision
    );

    const configuredResultSet = new Set<string>();
    const unconfiguredResultSet = new Set<string>();

    const sortedResults = resultsToDisplay
      .reduce((prev, cur) => {
        prev.push(...(cur.result?.targets ?? []));
        return prev;
      }, <SavannaTarget[]>[])
      .sort((a, b) => a.displaySequence - b.displaySequence);

    sortedResults.forEach(st => {
      if (st.hasConfig && !configuredResultSet.has(st.shortName)) {
        configuredResultSet.add(st.shortName);
      } else if (!st.hasConfig && !unconfiguredResultSet.has(st.shortName)) {
        unconfiguredResultSet.add(st.shortName);
      }
    });

    this.tableColumns = [...this.defaultTableColumns];

    for (let result of configuredResultSet) {
      this.tableColumns.push({
        field: result,
        header: result,
        initialIndex: this.tableColumns.length,
        parentField: 'assay',
        visible: true,
      });
      this.dynamicColumns.push(result);
    }

    for (let result of unconfiguredResultSet) {
      this.tableColumns.push({
        field: result,
        header: result,
        initialIndex: this.tableColumns.length,
        parentField: 'assay',
        visible: true,
      });
      this.dynamicColumns.push(result);
    }

    this.selectedColumns = this.tableColumns;

    this.getDynamicColsConfig(this.combinedDetailsAndResults);
    this.updateResultCounts();
    this.filterChangedUpdateCounts();
    this.restoreTableState();
    this.refreshFrozenRowStyles();

    this.groupBySampleAndCondition();
  }

  mergeDetailsAndResults(details: Detail[], targetErrors: DeviceTargetError[], instrumentErrors: InstrumentError[], resultsAndConfigs?: SavannaResultsAndConfig) {
    if (!resultsAndConfigs) {
      return;
    }
    // first we'll sort by the details, and that will dictate the order
    // since we're attaching the found SavannaResults to Details
    this.apiService.detailsDefaultSort(details);

    this.assayIdRevisionGroups = [];

    if (resultsAndConfigs.primaryAssay) {
      this.assayIdRevisionGroups.push({
        value: resultsAndConfigs.primaryAssay,
        viewValue: `${resultsAndConfigs.primaryAssay.assayShortName} ${resultsAndConfigs.primaryAssay.assayId}.${resultsAndConfigs.primaryAssay.assayRevision}`,
      });
    }

    if (resultsAndConfigs.outlierAssays && resultsAndConfigs.outlierAssays.length > 0) {
      for (let outlierAssay of resultsAndConfigs.outlierAssays) {
        this.assayIdRevisionGroups.push({
          value: outlierAssay,
          viewValue: `${outlierAssay.assayShortName} ${outlierAssay.assayId}.${outlierAssay.assayRevision}`,
        });
      }
    }

    this.selectedAssayIdRevision =
      this.assayIdRevisionGroups.length > 0 ? this.assayIdRevisionGroups[0].value : undefined;

    const results = resultsAndConfigs.results;

    // loop through all details and find a corresponding Savanna Result for each one
    details.forEach(detail => {
      const targetError = targetErrors.find(x => x.detailId == detail.detailId);
      if (targetError) {
        detail.unevaluable = true;
        detail.targetError = targetError;
      }

      const instrumentError = instrumentErrors.find(x => x.detailId == detail.detailId && x.isActive === true);
      if (instrumentError) {
        detail.instrumentError = true;
        detail.instrumentErrorText = instrumentError.errorText;
      }

      // to find duplicates in the results, we'll filter instead of find and see if there is more than one
      const resultsFound = results.filter(result => {
        return result.sampleId.trim() == detail.barcodeID.trim();
      });

      let found: SavannaResult | undefined;
      let otherResults: SavannaResult[] = [];
      let foundDuplicates: boolean = false;

      if (resultsFound.length == 1) {
        found = resultsFound[0];
      } else if (resultsFound.length > 1) {
        // there is more than one result for this barcode
        // sort them descending by DATE and take the first one (most recent)
        let resultsFoundSorted = resultsFound.sort((a: SavannaResult, b: SavannaResult): number => {
          return a.timestamp < b.timestamp ? 1 : -1;
        });

        resultsFoundSorted.forEach(e => {
          console.log(`    ${e.timestamp}`);
        });

        console.warn(
          `found duplicate results for ${detail.barcodeID}. count ${resultsFoundSorted.length}. using first one (most recent date).`
        );

        found = resultsFoundSorted[0];
        otherResults = resultsFoundSorted.slice(1);

        const props: { [key: string]: string } = {
          ExperimentId: this.currentExperimentId.toString(),
          BarcodeId: detail.barcodeID,
          Count: resultsFoundSorted.length.toString(),
        };

        this.loggingService.logEvent(EVENT_SAVANNARESULTS_FOUND_DUPLICATES, props);

        foundDuplicates = true;
      }

      this.setResultToUnevaluable(targetError, !!instrumentError, [found!]);
      this.setResultToUnevaluable(targetError, !!instrumentError, otherResults);

      const combined: DetailAndSavannaResult = {
        detail: detail,
        result: found,
        targetError: targetError,
        hasDuplicateResults: foundDuplicates,
        otherResults: otherResults,
      };

      this.combinedDetailsAndResults.push(combined);
    });

    this.initializeColumns();
  }

  setResultToUnevaluable(targetError: DeviceTargetError | undefined, instrumentError: boolean | undefined, results: SavannaResult[]) {
    for (let result of results) {
      if (!result) continue;

      result.targets.forEach(t => {
        t.isUnevaluable = false;
      });
      // Has result and target error
      if (targetError) {
        targetError.errorTargets.forEach(et => {
          // Entire cartridge it's failing mark all targets as unevaluable.
          if (et.chamberNum === null && et.channelNum === null) {
            result?.targets.forEach(t => { t.isUnevaluable = true; });
          }
          // Chamber it's failing mark chamber target as unevaluables
          else if (et.chamberNum !== null && et.channelNum === null) {
            result?.targets.filter(t => t.chamber === et.chamberNum).forEach(t => t.isUnevaluable = true);
          }
          // Chamber-Channel it's failing mark chamber-channel target as unevaluables
          else {
            result?.targets.filter(t => t.chamber === et.chamberNum && t.channel === et.channelNum).forEach(t => t.isUnevaluable = true);
          }
        });
      }

      // Has result and instrument error
      if (instrumentError) {
        result.targets.forEach(t => {
          t.isUnevaluable = true;
        });
      }
    }
  }

  getDynamicColsConfig(data: DetailAndSavannaResult[]) {
    const dynamicColsConfig: {
      columnName: string,
      shortName: string,
      savannaTarget: SavannaTarget
    }[] = [];

    for (let assayGroup of this.assayIdRevisionGroups) {
      const displayResults = data.filter(
        cdr =>
          cdr.result?.assayId == assayGroup.value.assayId && cdr.result?.assayRevision == assayGroup.value.assayRevision
      );

      const configuredResultSet: { [shortName: string]: SavannaTarget } = {};
      const unconfiguredResultSet: { [shortName: string]: SavannaTarget } = {};

      const sortedResults = displayResults
        .reduce((prev, cur) => {
          prev.push(...(cur.result?.targets ?? []));
          return prev;
        }, <SavannaTarget[]>[])
        .sort((a, b) => a.displaySequence - b.displaySequence);

      sortedResults.forEach(st => {
        if (st.hasConfig && !configuredResultSet[st.shortName]) {
          configuredResultSet[st.shortName] = st;
        } else if (!st.hasConfig && !unconfiguredResultSet[st.shortName]) {
          unconfiguredResultSet[st.shortName] = st;
        }
      });

      for (let headerColNameKey of Object.keys(configuredResultSet)) {
        dynamicColsConfig.push({
          columnName: `${assayGroup.value.assayShortName}_${assayGroup.value.assayId}_${assayGroup.value.assayRevision}_${configuredResultSet[headerColNameKey].shortName}`,
          shortName: configuredResultSet[headerColNameKey].shortName,
          savannaTarget: configuredResultSet[headerColNameKey]
        });
      }

      for (let headerColNameKey of Object.keys(unconfiguredResultSet)) {
        dynamicColsConfig.push({
          columnName: `${assayGroup.value.assayShortName}_${assayGroup.value.assayId}_${assayGroup.value.assayRevision}_${unconfiguredResultSet[headerColNameKey].shortName}`,
          shortName: unconfiguredResultSet[headerColNameKey].shortName,
          savannaTarget: unconfiguredResultSet[headerColNameKey]
        });

      }
    }

    this.columnTotals = dynamicColsConfig.map(dc => {
      return {
        columnName: dc.columnName,
        shortName: dc.shortName,
        Positive: 0,
        Negative: 0,
        Invalid: 0,
        NotEvaluableEmpty: 0,
        NotEvaluablePositive: 0,
        NotEvaluableNegative: 0,
        NotEvaluableInvalid: 0,
        savannaTarget: dc.savannaTarget
      }
    });

    return dynamicColsConfig;
  }

  downloadCsvFile(onlyInvalidAnalytes: boolean) {
    // We don't use tableData as we always want all results
    let data = this.combinedDetailsAndResults;

    const dynamicColsConfig: {
      columnName: string,
      savannaTarget: SavannaTarget
    }[] = this.getDynamicColsConfig(data);

    const dynamicCols: string[] = dynamicColsConfig.map(dcc => dcc.columnName);

    const headers = this.staticColumns
      .concat(dynamicCols)
      .concat(['hasDuplicateResults', 'assayIdOrRevisionMismatch'])
      .concat(['assayId', 'assayRevision'])
      .map(header => this.camelCaseToPascalCase(header));

    let timestampIndex = headers.findIndex(x => x === 'Timestamp');
    headers[timestampIndex] += "UTC";

    let csv: string[][] = [];

    // Special filter cases like show only records with invalid analytes
    if (onlyInvalidAnalytes === true) {
      data = data.filter(record => record.result?.targets.reduce((prev, curr) => prev || curr.resultInvalid, false));
    }



    data
      .sort((prev, cur) => prev.detail.barcodeID.localeCompare(cur.detail.barcodeID))
      .forEach((detailAndSavannaResult: DetailAndSavannaResult) => {
        let row = this.buildOneCsvRow(detailAndSavannaResult, detailAndSavannaResult.result, dynamicCols, true);
        csv.push(row);

        // add any duplicates before we move on
        if (
          detailAndSavannaResult.hasDuplicateResults ||
          (detailAndSavannaResult.otherResults != undefined && detailAndSavannaResult.otherResults.length > 0)
        ) {
          detailAndSavannaResult.otherResults.forEach(otherResult => {
            row = this.buildOneCsvRow(detailAndSavannaResult, otherResult, dynamicCols, true);
            csv.push(row);
          });
        }
      });
    csv.unshift(headers);

    let filename = this.apiService.formatDateToDateAndTime(new Date());

    if (onlyInvalidAnalytes === true) {
      filename += "-InvalidAnalytesOnly";
    }

    const workBook: XLSX.WorkBook = XLSX.utils.book_new();

    let xlsxData = csv.map((row, rowIndex) => {
      return row.map((col, colIndex) => {
        let data = {
          v: col,
          t: 's',
          s: {}
        }
        if (rowIndex > 0 && (colIndex == 5 || colIndex == 7)) {
          data.s = {
            alignment: {
              wrapText: true
            }
          };
        }
        return data;
      });
    });
    const worksheetData = XLSX.utils.aoa_to_sheet(xlsxData);

    let resultsSummary = this.getResultsSummarySheet();
    const workSheetResultsSummary = XLSX.utils.aoa_to_sheet(resultsSummary);

    XLSX.utils.book_append_sheet(workBook, worksheetData, `${this.currentExperimentId}`);
    XLSX.utils.book_append_sheet(workBook, workSheetResultsSummary, 'Results-Summary');

    worksheetData['!cols'] = this.autofitColumns(csv);
    workSheetResultsSummary['!cols'] = this.autofitColumns(resultsSummary);

    XLSX.writeFile(workBook, `LabPartner-SavannaResults-${this.currentExperimentId}-${filename}` + '.xlsx', { bookType: 'xlsx', type: 'buffer' });

    const props: { [key: string]: number } = { ExperimentId: this.currentExperimentId };
    this.loggingService.logEvent(EVENT_SAVANNA_DOWNLOAD_CSV, props);
  }

  private autofitColumns(data: string[][]) {
    // Calculate column widths based on content
    const columnWidths = data[0].map(header => ({
      wch: 0,
    }));
    data.forEach(row => {
      row.forEach((cell, index) => {
        if (cell.split("\n").length > 1) {
          let lineWidth = cell.split('\n').map(l => l.length).sort((a, b) => b - a)[0];

          if (lineWidth > (columnWidths[index].wch || 0)) {
            columnWidths[index].wch = lineWidth;
          }
        } else if (cell.length > (columnWidths[index].wch || 0)) {
          columnWidths[index].wch = cell.length;
        }

      });
    });
    return columnWidths;
  }

  private syncColumnVisibilityWithConfig() {
    const columnConfig = window.sessionStorage.getItem(`${this.tableStateKey}_VisibleColumns`);
    const orderedColumns = columnConfig ? (JSON.parse(columnConfig) as IColumnDefinition[]) : [];

    if (orderedColumns.length) {
      this.selectedColumns = orderedColumns.filter(x => x.visible);
    }
  }

  // This addresses styling issues that can occur when the 'size' of data displayed changes significantly
  private refreshFrozenRowStyles() {
    setTimeout(() => this.frozenColumnsQl.forEach(fc => fc.updateStickyPosition()));
  }

  private saveTableState() {
    setTimeout(() => {
      this.setTableStateKey();
      this.primeTable.saveState();

      const hiddenColumns = this.tableColumns.filter(x => !x.visible);

      if (hiddenColumns.length) {
        let allColumns: IColumnDefinition[] = [];
        hiddenColumns.forEach(hiddenCol => {
          allColumns = this.selectedColumns
            .slice(0, hiddenCol.initialIndex)
            .concat([hiddenCol])
            .concat(this.selectedColumns.slice(hiddenCol.initialIndex));
        });

        window.sessionStorage.setItem(`${this.tableStateKey}_VisibleColumns`, JSON.stringify(allColumns));
      } else {
        window.sessionStorage.setItem(`${this.tableStateKey}_VisibleColumns`, JSON.stringify(this.tableColumns));
      }
    });
  }

  private restoreTableState() {
    if (!this.primeTable) return;
    this.setTableStateKey();
    this.primeTable.restoreState();

    this.syncColumnVisibilityWithConfig();

    // For some reason, this doesn't work unless it happens a tick after everything else, so that is why this is here
    setTimeout(() => {
      this.primeTable.restoreColumnWidths();

      this.resyncColumnOrder();
    });
  }

  private resyncColumnOrder() {
    // Instead of relying on the table itself to restore column order, we'd like to control it ourselves
    // this ensures the column selector stays ordered and that the two don't get out of sync reference-wise
    let newTableColumns: IColumnDefinition[] = [];
    const tableConfig = window.sessionStorage.getItem(`${this.tableStateKey}`);
    const columnOrder = tableConfig ? (JSON.parse(tableConfig) as { columnOrder: string[] }).columnOrder : [];
    if (columnOrder.length) {
      columnOrder.forEach(colField => {
        const tableCol = this.tableColumns.find(x => x.field == colField);
        if (tableCol) {
          newTableColumns.push(tableCol);
        }
      });

      const unorderedColumns = this.tableColumns.filter(x => !columnOrder.includes(x.field));
      unorderedColumns.forEach(unorderedColumn => {
        unorderedColumn.visible = false;
        newTableColumns = newTableColumns
          .slice(0, unorderedColumn.initialIndex)
          .concat([unorderedColumn])
          .concat(newTableColumns.slice(unorderedColumn.initialIndex));
      });

      this.tableColumns = newTableColumns;
      this.selectedColumns = this.tableColumns.filter(x => x.visible);
    }
  }

  private buildOneCsvRow(
    d: DetailAndSavannaResult,
    result: SavannaResult | undefined,
    dynamicCols: string[],
    shouldCountTotal: boolean
  ): string[] {
    if (d.detail.instrumentError && !result && shouldCountTotal) {
      this.columnTotals.forEach(c => c.NotEvaluableEmpty++);
    }
    if (d.detail.targetError && !result && shouldCountTotal) {
      d.detail.targetError.errorTargets.forEach(et => {
        // Entire cartridge it's failing.
        if (et.chamberNum === null && et.channelNum === null) {
          this.columnTotals.forEach(ct => ct.NotEvaluableEmpty++);
        }
        else if (et.chamberNum !== null && et.channelNum === null) {
          this.columnTotals.filter(ct => ct.savannaTarget?.chamber == et.chamberNum).forEach(ct => ct.NotEvaluableEmpty++);
        }
        else {
          this.columnTotals.filter(ct => ct.savannaTarget?.chamber == et.chamberNum && ct.savannaTarget?.channel == et.channelNum).forEach(ct => ct.NotEvaluableEmpty++);
        }
      });
    }
    let row: string[] = [];
    let sampleTubeID = d.detail.barcodeID ? d.detail.barcodeID.split("_")[0] + '_' + d.detail.barcodeID.split("_")[1] : 'N/A';
    let hasUnvaluable = d.detail?.targetError?.errorTargets && d?.detail?.targetError?.errorTargets[0]?.chamberNum == null && d?.detail?.targetError?.errorTargets[0]?.channelNum == null;
    let liveNote = d?.detail.liveNote ? d.detail.liveNote.trim() : '';
    let notes = d?.detail.notes ? d.detail.notes.trim() : '';

    const orderedDataPoints = [
      d.detail.barcodeID ?? 'N/A',
      d.detail.sampleTubeID ? d.detail.sampleTubeID : sampleTubeID,
      result?.cartridgeLot ?? 'N/A',
      result?.cartridgeSerialNumber ?? 'N/A',
      result?.instrumentId ?? 'N/A',
      (liveNote || notes ? liveNote + '\n' + notes : 'N/A'),
      hasUnvaluable ? 'No' : 'Yes',
      hasUnvaluable ? (d.detail?.targetError?.errorText ?? 'N/A') : "N/A",
      d.detail.sampleName,
      d.detail.conditionName,
      result?.assayFilename ?? '',
      result?.operatorName ?? '',
      result?.timestamp?.toString().replace("T", " ").replace("Z", "") ?? '',
      d.detail.instrumentError ? 'True' : 'False',
      ...dynamicCols.map(
        dc => this.getResult(d, result, dc, this.columnTotals, shouldCountTotal)
      ),
      this.camelCaseToPascalCase(d.hasDuplicateResults.toString()),
      this.camelCaseToPascalCase(d.result?.isAssayIdAndRevisionOutlier?.toString() ?? ''),
      result?.assayId.toString() ?? '',
      result?.assayRevision.toString() ?? '',
    ];

    for (let csvDataPoint of orderedDataPoints) {
      row.push(csvDataPoint);
    }

    return row;
  }

  private getSavannaResultsAndParseData(firstPrefix: string, details: Detail[], targetErrors: DeviceTargetError[], instrumentErrors: InstrumentError[], preserveSearch: boolean): Observable<SavannaResultsAndConfig> {
    this.isLoading = true;
    return this.apiService
      .getSavannaResults(firstPrefix)
      .pipe(
        tap((resultsAndConfig: SavannaResultsAndConfig) => {
          this.resultsAndConfig = resultsAndConfig;
          // we'll log the event here so we can report how many results were found for this experiment
          const props: { [key: string]: number } = {
            ExperimentId: this.currentExperimentId,
            Count: resultsAndConfig.results.length,
          };
          this.loggingService.logEvent(EVENT_SAVANNARESULTS, props);

          if (resultsAndConfig.results.length == 0) {
            this.notificationService.warn('No Savanna results found for this experiment.');
          }

          this.mergeDetailsAndResults(details, targetErrors, instrumentErrors, resultsAndConfig);

          if (preserveSearch) {
            // refresh the search filter based on preserved search key
            this.onSearchChanged(this.searchKey);
          }
        }),
        finalize(() => {
          this.isLoading = false;
          // For some reason the table paginator isn't updating and setting itself to the first page on load
          // this may be due to the odd way we're lazy-loading it, TBD
          this.primeTable.first = 0;
          this.restoreTableState();
          this.cdr.detectChanges();
        })
      );
  }

  private updateResultCounts() {
    // initialize our columnCounts
    this.dynamicColumns.forEach(shortName => {
      let colCount: { [key: string]: number } = {};
      this.columnCounts[shortName] = colCount;
    });

    this.applyFilterHideEmptyResults();
  }

  private tableSortDataAccessor(data: any, field: string): number | string {
    let ret = 'empty';

    switch (field) {
      case 'sampleName':
      case 'conditionName':
      case 'barcodeID':
      case 'instrumentError':
        ret = data.detail[field];
        break;

      case 'instrumentId':
      case 'operatorName':
      case 'timestamp':
        if (data.result) {
          ret = data.result[field];
        }
        break;

      default:
        const target = data.result?.targets?.find((t: SavannaTarget) => t.shortName == field);
        if (target) {
          ret = target?.qualitativeResult!;
        }
        break;
    }

    return ret;
  }

  private filterChangedUpdateCounts() {
    this.dynamicColumns.forEach(shortName => {
      this.columnCounts[shortName][this.RESULT_POSITIVE] = 0;
      this.columnCounts[shortName][this.RESULT_NEGATIVE] = 0;
      this.columnCounts[shortName][this.RESULT_INVALID] = 0;
      this.columnCounts[shortName][this.RESULT_UNEVALUABLE] = 0;
    });

    this.tableData.forEach((d: DetailAndSavannaResult) => {
      // If detail has result and otherResults if applies.
      if (d.result) {
        [d.result, ...d.otherResults].forEach(result => {
          for (let i = 0; i < result.targets.length; i++) {
            const shortName = result.targets[i].shortName;
            if (this.columnCounts[shortName] && this.validateCurrentLayout(result)) {
              if (result.targets[i].isUnevaluable) {
                this.columnCounts[shortName][this.RESULT_UNEVALUABLE]++;
              } else {
                this.columnCounts[shortName][result.targets[i].qualitativeResult]++;
              }
            }
          }
        });
      }
      // If detail has instrument error but no result
      else if (d.detail.instrumentError) {
        this.columnTotals.forEach(columnTotal => {
          if (this.columnCounts[columnTotal.shortName] && this.validateCurrentLayoutByName(columnTotal)) {
            this.columnCounts[columnTotal.shortName][this.RESULT_UNEVALUABLE]++;
          }
        })
      }
      // If detail has target error but no result
      else if (d.detail.targetError) {
        d.detail.targetError.errorTargets.forEach(et => {
          // Entire cartridge it's failing.
          if (et.chamberNum === null && et.channelNum === null) {
            this.columnTotals.forEach(columnTotal => {
              if (this.columnCounts[columnTotal.shortName] && this.validateCurrentLayoutByName(columnTotal)) {
                this.columnCounts[columnTotal.shortName][this.RESULT_UNEVALUABLE]++;
              }
            })
          }
          else if (et.chamberNum !== null && et.channelNum === null) {
            this.columnTotals.filter(ct => ct.savannaTarget?.chamber == et.chamberNum).forEach(columnTotal => {
              if (this.columnCounts[columnTotal.shortName] && this.validateCurrentLayoutByName(columnTotal)) {
                this.columnCounts[columnTotal.shortName][this.RESULT_UNEVALUABLE]++;
              }
            })
          }
          else {
            this.columnTotals.filter(ct => ct.savannaTarget?.chamber == et.chamberNum && ct.savannaTarget?.channel == et.channelNum).forEach(columnTotal => {
              if (this.columnCounts[columnTotal.shortName] && this.validateCurrentLayoutByName(columnTotal)) {
                this.columnCounts[columnTotal.shortName][this.RESULT_UNEVALUABLE]++;
              }
            });
          }
        });
      }
    });
  }

  private applyFilterHideEmptyResults(searchKey?: string) {
    if (searchKey) {
      const filteredResults: DetailAndSavannaResult[] = [];
      filteredResults.push(
        ...this.combinedDetailsAndResults.filter((result: DetailAndSavannaResult | { [key: string]: any }) => {
          // first check if this is an empty row, regardless of columns to check
          if (this.hideEmptyResults && !result.result) {
            return false;
          }

          if ((result.detail.unevaluable) && "unevaluable".indexOf(searchKey) != -1) {
            return true;
          }

          return this.tableColumns.some(col => {
            if (
              (result.detail != undefined &&
                result.detail[col.field] != undefined &&
                result.detail[col.field].toString().toLowerCase().indexOf(searchKey) != -1) ||
              (result.result != undefined &&
                result.result[col.field] != undefined &&
                result.result[col.field].toString().toLowerCase().indexOf(searchKey) != -1)
            ) {
              return true;
            }

            if (result.result != undefined) {
              const found = result.result.targets.some((t: SavannaTarget) => {
                return t.qualitativeResult.toLowerCase().indexOf(searchKey) != -1;
              });
              return found;
            }

            return false;
          });
        })
      );
      this.combinedDetailsAndResultsFiltered = filteredResults;
    } else if (this.hideEmptyResults) {
      this.combinedDetailsAndResultsFiltered = this.combinedDetailsAndResults
        .filter(result => {
          return result.result;
        })
        .filter(
          result =>
            result.result?.assayId == this.selectedAssayIdRevision?.assayId &&
            result.result?.assayRevision == this.selectedAssayIdRevision?.assayRevision
        );
    } else {
      // Break referential integrity here so that the table's paginator updates appropriately
      this.combinedDetailsAndResultsFiltered = JSON.parse(JSON.stringify(this.combinedDetailsAndResults));
    }

    this.combinedDetailsAndResultsFiltered.forEach(x => {
      const targetMap: { [key: string]: SavannaTarget } = {};
      if (x.result) {
        x.result.targets.forEach(t => {
          targetMap[t.shortName] = t;
        });
        x.result.shortNameTargetMap = targetMap;
      }
      x.otherResults?.forEach(otherResult => {
        const targetMap: { [key: string]: SavannaTarget } = {};
        otherResult.targets.forEach(t => {
          targetMap[t.shortName] = t;
        });
        otherResult.shortNameTargetMap = targetMap;
      })
    });

    this.tableData = this.combinedDetailsAndResultsFiltered;
  }

  private setTableStateKey() {
    if (!this.primeTable) return;

    this.primeTable.stateKey = this.tableStateKey;
    this.primeTable.stateStorage = 'session';
  }

  private updateWidthOfNewlyVisibleColumn(idx: number, tableReset: boolean = false) {
    this.primeTable.cd.detectChanges();

    setTimeout(() => {
      if (!this.primeTable || !this.primeTable.tableHeaderViewChild) return;

      // Get the newly added column's header
      let newlyVisibleColumn = this.primeTable.tableHeaderViewChild.nativeElement.getElementsByTagName('th')[idx];
      if (newlyVisibleColumn === undefined) {
        newlyVisibleColumn = this.primeTable.tableHeaderViewChild.nativeElement.getElementsByTagName('th')[0];
      }
      // Get the column's first row child
      let runDateCol: HTMLTableCellElement | null = null;
      if (tableReset) {
        const firstRow = document.getElementsByTagName('tr')[1];
        runDateCol = firstRow?.getElementsByTagName('td')[6];
      }

      // Set the context of a resize event based on the newly visible column
      this.primeTable.resizeColumnElement = newlyVisibleColumn;

      const finalWidth = Math.max(newlyVisibleColumn?.scrollWidth, runDateCol?.scrollWidth ?? 0);

      // Fire off an update to the newly visible column as if it were being resized, but to its scrollWidth to capture contents (text & sort icon)
      this.primeTable.resizeTableCells(
        finalWidth,
        newlyVisibleColumn.offsetWidth - (finalWidth - newlyVisibleColumn.width)
      );
    });
  }

  public columnHasUnevaluable(rowData: DetailAndSavannaResult, field: string) {
    var target = this.columnTotals.find(ct => ct.columnName.includes(this.selectedAssayIdRevision?.assayShortName!) && ct.savannaTarget?.shortName == field);
    if (!target || !rowData.targetError) {
      return false;
    }

    return rowData.targetError.errorTargets.filter(et => {
      // Entire cartridge it's failing.
      if (et.chamberNum === null && et.channelNum === null) {
        return true;
      }
      else if (et.chamberNum !== null && et.channelNum === null) {
        return target?.savannaTarget?.chamber === et.chamberNum;
      }
      else {
        return target?.savannaTarget?.chamber === et.chamberNum && target?.savannaTarget?.channel == et.channelNum;
      }
    }).length > 0;
  }

  private camelCaseToPascalCase(str: string): string {
    if (!str || str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  async toggleDetailInstrumentError(row: DetailAndSavannaResult, evt: MatCheckboxChange) {
    if (!this.currentExperiment) return;

    const prevValue = row.detail.instrumentError;

    const dialogConfig = new MatDialogConfig<InstrumentErrorDialogData>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.data = {
      experiment: this.currentExperiment,
      detail: row.detail,
      selectedDetails: [row.detail]
    };

    const dialogRef = this.dialog.open<InstrumentErrorDialogComponent, InstrumentErrorDialogData, InstrumentErrorDialogResult>(
      InstrumentErrorDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult && dialogResult.success) {

        evt.source.checked = !!dialogResult.data.value;
        row.detail.instrumentError = !!dialogResult.data.value;
        row.detail.instrumentErrorText = dialogResult.data.value ? dialogResult.data.value : "";

        let index = this.combinedDetailsAndResults.findIndex(x => x.detail.detailId == row.detail.detailId);
        this.combinedDetailsAndResults[index] = row;

        this.setResultToUnevaluable(row.detail.targetError, row.detail.instrumentError, [row.result!]);
        this.setResultToUnevaluable(row.detail.targetError, row.detail.instrumentError, row.otherResults);

        this.groupBySampleAndCondition();
        this.filterChangedUpdateCounts();

        if (dialogResult.updated) {
          this.notificationService.success('Instrument Error record updated');
        } else {
          this.notificationService.success('Instrument Error recorded successfully');
        }
      } else {
        this.notificationService.warn(':: Canceled');
        evt.source.checked = prevValue;
        row.detail.instrumentError = prevValue;
      }
    });
  }

  async toggleDetailUnevaluable(row: DetailAndSavannaResult, evt: MatCheckboxChange) {
    if (!this.currentExperiment) return;

    const prevValue = row.detail.unevaluable;

    const deviceName = this.experimentService.devices.find(x => x.id == this.currentExperiment?.deviceType)?.value;
    const dialogConfig = new MatDialogConfig<DeviceTargetErrorDialogData>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    dialogConfig.height = deviceName === 'Savanna' ? '670px' : '330px';
    dialogConfig.data = {
      experiment: this.currentExperiment,
      detail: row.detail,
      selectedDetails: [row.detail]
    };

    const dialogRef = this.dialog.open<DeviceTargetErrorDialogComponent, DeviceTargetErrorDialogData, DeviceTargetErrorDialogResult>(
      DeviceTargetErrorDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.success) {
        evt.source.checked = !!dialogResult.data.value;
        row.detail.unevaluable = !!dialogResult.data.value;
        row.targetError = dialogResult.data.value;
        row.detail.targetError = dialogResult.data.value;

        let index = this.combinedDetailsAndResults.findIndex(x => x.detail.detailId == row.detail.detailId);
        this.combinedDetailsAndResults[index] = row;

        this.setResultToUnevaluable(row.detail.targetError, row.detail.instrumentError, [row.result!]);
        this.setResultToUnevaluable(row.detail.targetError, row.detail.instrumentError, row.otherResults);

        this.groupBySampleAndCondition();
        this.filterChangedUpdateCounts();

        if (dialogResult.updated) {
          this.notificationService.success('Not Evaluable record updated');
        } else {
          this.notificationService.success('Not Evaluable recorded successfully');
        }
      } else {
        this.notificationService.warn(':: Canceled');
        evt.source.checked = prevValue;
        row.detail.unevaluable = prevValue;
      }
    });
  }

  private getResult(d: DetailAndSavannaResult, result: SavannaResult | undefined, dc: string, columnTotals: ColumnTotal[], shouldCountTotal: boolean) {
    let target = result?.targets?.find(
      t => `${result?.assayShortName}_${result?.assayId}_${result?.assayRevision}_${t.shortName}` == dc
    )
    let total = columnTotals.find(x => x.columnName === dc) as any;
    if (target && total && shouldCountTotal) {
      total[(target.isUnevaluable ? 'NotEvaluable' : '') + target.qualitativeResult]++;
    }

    if (target) {
      return (target.isUnevaluable ? 'NotEvaluable' : '') + target.qualitativeResult;
    } else if (!result) {
      if (d.detail.instrumentError) {
        return 'NotEvaluableEmpty';
      }

      if (d.detail.targetError) {
        return d.detail.targetError.errorTargets.filter(et => {
          // Entire cartridge it's failing.
          if (et.chamberNum === null && et.channelNum === null) {
            return true;
          }
          else if (et.chamberNum !== null && et.channelNum === null) {
            var columnTotal = columnTotals.find(ct => ct.columnName == dc);
            return columnTotal?.savannaTarget?.chamber == et.chamberNum;
          }
          else {
            var columnTotal = columnTotals.find(ct => ct.columnName == dc);
            return columnTotal?.savannaTarget?.chamber == et.chamberNum && columnTotal?.savannaTarget?.channel == et.channelNum;
          }
        }).length > 0 ? 'NotEvaluableEmpty' : '';
      }

    }
    return '';
  }

  /**
   * Funtion to process each detail grouped by sample and condition
   */
  private groupBySampleAndCondition() {
    let resultsMap: any = {};

    this.combinedDetailsAndResults.forEach(d => {
      let key = d?.detail?.sampleId + '_' + d?.detail?.conditionId;
      // If key does not exist init counters
      if (!resultsMap[key]) {
        resultsMap[key] = {
          sampleName: d?.detail?.sampleName,
          conditionName: d?.detail?.conditionName,
          record: d
        };

        this.columnTotals.forEach(ct => {
          resultsMap[key][ct.columnName] = {
            positive: 0,
            negative: 0,
            invalid: 0,
            unevaluable: 0
          };
        });
      }

      let record = resultsMap[key];

      this.columnTotals.forEach(ct => {
        [d.result, ...d.otherResults].forEach((result?: SavannaResult) => {
          let resultStr = this.getResult(d, result, ct.columnName, this.columnTotals, false);

          if (resultStr.toUpperCase().includes("UNEVALUABLE")) {
            record[ct.columnName].unevaluable++;
          }
          else if (resultStr) {
            record[ct.columnName][resultStr.toLowerCase()]++;
          }
        })
      });
    });

    this.combinedDetailsAndResultsGrouped = Object.values(resultsMap);
    this.onResultsLoaded.emit({ data: this.combinedDetailsAndResultsGrouped, dynamicColumns: this.getDynamicColsConfig(this.combinedDetailsAndResults).map(c => c.columnName) });
  }

  private getResultsSummarySheet() {
    return [
      ['Sample', 'Condition', ...this.columnTotals.map(x => x.columnName)],
      ...this.combinedDetailsAndResultsGrouped.map((d: any) => {
        return [
          d.sampleName,
          d.conditionName,
          ...this.columnTotals.map(x =>
            `${d[x.columnName].positive}/${d[x.columnName].negative}/${d[x.columnName].invalid}/${d[x.columnName].unevaluable}`
          )]
      })
    ]
  }

  /**
   * Compares the full layout name by properties.
   * @param result 
   * @returns 
   */
  validateCurrentLayout(result: SavannaResult) {
    // If no layout selected break to avoid issues
    if (!this.selectedAssayIdRevision) return false;

    // In case that the detail has no results, show unevaluable if applies
    if (!result) return true;

    return result.assayId === this.selectedAssayIdRevision.assayId
      && result.assayRevision === this.selectedAssayIdRevision.assayRevision
      && result.assayShortName === this.selectedAssayIdRevision.assayShortName;
  }
  /**
   * Compares the full layout name in a string.
   * @param columnTotal 
   * @returns 
   */
  validateCurrentLayoutByName(columnTotal: ColumnTotal) {
    // If no layout selected break to avoid issues
    if (!this.selectedAssayIdRevision) return false;

    return columnTotal.columnName === `${this.selectedAssayIdRevision.assayShortName}_${this.selectedAssayIdRevision.assayId}_${this.selectedAssayIdRevision.assayRevision}_${columnTotal.shortName}`;
  }
}
