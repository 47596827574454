export const CreatedStatus = 1;
export const DetailsGeneratedStatus = 2;
export const ReadyToTestStatus = 3;
export const CompleteStatus = 4;

export enum ExperimentStatus {
  Created = 1,
  DetailsGenerated = 2,
  ReadyToTest = 3,
  Complete = 4,
}

export enum VerbedExperimentStatus {
  IsCreated = 1,
  IsDetailsGenerated = 2,
  IsReadyToTest = 3,
  IsComplete = 4,
  IsNotComplete = 5,
  IsLessThanReadyToTest = 6,
  IsGreaterThanDetailsGenerated = 7,
  IsAnyStatus = 8,
}

export enum ExperimentTypeDisplayName {
  ResearchAndDevelopment = 'R&D',
  ValidationAndVerification = 'V&V',
}

export enum ExperimentType {
  ResearchAndDevelopment = 0,
  ValidationAndVerification = 1,
}

export enum StudyType {
  LoDRangeFinding = 0,
  LoDConfirmation = 1,
  ImportExcel = 2,
  General = 3,
  DailyExternalControls = 4,
  Precision = 5
}

export enum AutomatedReportStatus {
  Created = 1,
  Completed = 2,
  Failed = 3,
}

export const StudyTypes = [
  { id: StudyType.LoDRangeFinding, name: 'LoD - Range Finding' },
  { id: StudyType.LoDConfirmation, name: 'LoD - Confirmation' },
  { id: StudyType.ImportExcel, name: 'Import Excel' },
  { id: StudyType.General, name: 'General' },
  { id: StudyType.DailyExternalControls, name: 'Daily External Controls' },
  { id: StudyType.Precision, name: 'Precision' },
]

export enum BundleStudyType {
  LoD = 0,
  Precision = 5
}

export const BundleStudyTypes = [
  { id: BundleStudyType.LoD, name: 'LoD' },
  { id: BundleStudyType.Precision, name: 'Precision' }
]

export interface AuditedChangeResponse {
  statusCode: number;
  message: string;
  expectedException: boolean;
  exception: any;
  exceptionStackTrace: string;
}

export interface Experiment {
  experimentId: number;
  name: string;
  assayName: string;
  protocolNumber: string;
  deviceType: number;
  replicates: number;
  status: number;
  type: ExperimentType;
  studyType: StudyType;
  assayNameId?: number | null;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: number;
  isArchived: number;

  samples?: Sample[];
  conditions?: Condition[];
}

export interface ExperimentExtended extends Experiment {
  createdByName: string;
  deviceName: string;
  expStatus: string;
  expNum: string;
}

export interface Sample {
  sampleId: number;
  experimentId: number;
  label?: string;
  name: string;
  importData: string;
  unevaluable?: boolean;
  dateCreated?: Date;
  dateUpdated?: Date;
  createdBy?: number;
  matrix?: string;
  matrixLotNumber?: string;
  prepEvent?: string;
  analytes: any[];
}

export interface InputsHeaderOrder {
  inputsHeaderOrderId: number;
  experimentId: number;
  inputType: number;
  headers: string;
}

export interface Logistic {
  logisticId: number;
  experimentId: number;
  importData: string;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: number;
}

export interface SampleDisplay {
  sampleId: number;
  experimentId: number;
  label: string;
  name: string;
  header: string[];
  data: string[];
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: number;
}

export interface Condition {
  conditionId: number;
  experimentId: number;
  label?: string;
  name: string;
  importData: string;
  unevaluable?: boolean;
  dateCreated?: Date;
  dateUpdated?: Date;
  createdBy?: number;
  cartridgeLot?: string;
  testingDate?: string;
  precisionDay?: string;
  precisionEvent?: string;
}

export interface ConditionDisplay {
  conditionId: number;
  experimentId: number;
  label: string;
  name: string;
  data: any;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: number;
  cartridgeLot?: string;
  testingDate?: string;
  precisionDay?: string;
  precisionEvent?: string;
}

export interface ImportData {
  importDataId: number;
  experimentId: number;
  type: number;
  importSource: string;
  importHeader: string;
  dateCreated: Date;
  createdBy: number;
}

export interface Note {
  noteId: number;
  experimentId: number;
  objectId: number;
  objectName: string;
  noteTitle: string;
  noteText: string;
  dateCreated: Date;
  createdBy: number;
  // UI Extensions
  createdByName?: string;
  selected?: boolean;
}

export enum NotesObjectName {
  Condition = 'Condition',
  Detail = 'Detail',
  DetailLiveNote = 'DetailLiveNote',
  Experiment = 'Experiment',
  Samples = 'Samples',
  FileUpload = 'FileUpload',

}

export type NotesMap = Partial<{ [k in keyof typeof NotesObjectName]: Note[] }>;
export type NotesCountMap = { [key: number]: number };
export type NotesForExperimentCountMap = { [k in keyof typeof NotesObjectName]: { [key: number]: number } };

export interface Detail {
  detailId: number;
  experimentId: number;
  conditionId: number;
  sampleId: number;
  replicateNo: number;
  conditionLabel: string;
  sampleLabel: string;
  experimentName: string;
  conditionName: string;
  cartridgeLot?: string;
  precisionDay?: number;
  precisionEvent?: number;
  testingDate?: string;
  sampleName: string;
  readableBarcode: string;
  barcodeID: string;
  sampleTubeID: string;
  deviceID: string;
  unevaluable: boolean;
  sampleIgnore: boolean;
  conditionIgnore: boolean;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: number;
  instrumentError: boolean;
  instrumentErrorText: string;
  liveNote: string;
  notes: string;
  targetError: DeviceTargetError;

  lodInfo?: string;
  precisionInfo?: string;
  decInfo?: string;
}

export interface CsvReport extends Detail {
  detailNotes: string;
  experimentNotes: string;
  sampleNotes: string;
  conditionNotes: string;
  experimentDetail: string;
  conditionDetail: string;
  sampleDetail: string;
  experimentType: ExperimentType;
}

export interface Bundle {
  bundleId: number;
  name: string;
  bundleType: number;
  instrumentSerialNumbers: string;
  instrumentSerialNumbersCount: string;
  experimentIds: string;
  experimentIdsCount: number;
  assayTypeCode: string;
  startDate: Date;
  endDate: Date;
  dateCreated: Date;
  createdBy: number;
  isClinicalTrial: boolean;
  isUsedToGenerateReport: boolean;
  bundleStudyType?: number;
  assayNameId?: number;
  dailyExternalControlExperimentId?: number;
}

export interface AssayType {
  Id: number;
  name: string;
  code: string;
}

export type SavannaConfigResults = { [key: number]: { [key: number]: SavannaAssayRevisionConfig[] } };

export interface SavannaTarget {
  shortName: string;
  longName: string;
  displaySequence: number;
  qualitativeResult: string;
  resultInvalid: boolean;
  chamber: number;
  channel: number;
  hasConfig: boolean;
  isUnevaluable: boolean;
}

export interface SavannaAssayRevisionConfig {
  assayConfigId?: number;
  assayId?: number;
  assayRevision?: number;
  chamber: number;
  channel: number;
  shortName?: string;
  position: number;

  assayNameId: number;
  assayName?: string;
}

export interface SavannaResultsAndConfig {
  configs: SavannaAssayRevisionConfig[];
  primaryAssay: SavannaAssayLayout;
  outlierAssays: SavannaAssayLayout[];
  results: SavannaResult[];
}

export interface SavannaAssayLayout {
  assayId: number;
  assayRevision: number;
  assayShortName: string;
}

export interface SavannaResult {
  testResultId: number;
  instrumentId: string;
  sampleId: string;
  operatorName: string;
  assayId: number;
  assayRevision: number;
  assayFilename: string;
  assayShortName: string;
  timestamp: Date;
  isAssayIdAndRevisionOutlier: boolean;
  cartridgeLot: string;
  cartridgeSerialNumber: string;
  targets: SavannaTarget[];
  shortNameTargetMap?: { [key: string]: SavannaTarget };
}

export interface DetailAndSavannaResult {
  detail: Detail;
  result: SavannaResult | undefined;
  targetError: DeviceTargetError | undefined,
  hasDuplicateResults: boolean;
  otherResults: SavannaResult[];
}

export interface DetailGroupedBySampleAndCondition {
  sampleName: string;
  conditionName: string;
  record: DetailAndSavannaResult
}

export interface User {
  userId: number;
  accountId: string;
  displayName: string;
  email: string;
}

export interface UserAccess extends User {
  hasFusionAccess: boolean;
}

export interface FileToUpload {
  uploadId: number;
  fileName: string;
  fileSize: number;
  fileType: string;
  lastModifiedTime: number;
  lastModifiedDate: Date;
  fileAsBase64: string;
  experimentId: number;
  dateCreated: Date;
  createdBy: number;
}

export interface IFileToUploadDisplay extends FileToUpload {
  createdByName: string;
}

export interface IdValuePair {
  id: string;
  value: string;
}

export interface KibanaData {
  experimentCount: number;
  urlTemplate: string;
  delayToRefreshSecs: number;
}

export interface InstrumentError {
  errorId: number;
  experimentId: number;
  detailId: number;
  instrumentNumber: string;
  errorText: string;
  isActive: boolean;
}
export interface DeviceTargetError {
  id: number;
  experimentId: number;
  detailId: number;
  errorText: string;
  isActive: boolean;
  errorTargets: ErrorTarget[];
}
export interface ErrorTarget {
  id: number;
  deviceTargetErrorId: number;
  chamberNum?: number;
  channelNum?: number;
  isActive: boolean;
}
export interface ErrorUserResponses {
  responseId: number;
  responseTitle: string;
  responseText: string;
}

export interface AssayName {
  id?: number;
  assayId: number;
  assayRevision: number;
  name: string;
  shortName: string;
  instrument: string;
  analyteTemplates: AnalyteTemplate[];
  isActive?: boolean;
  labelName?: string;
}

export interface AnalyteTemplate {
  analyteTemplateId: number;
  assayNameId: number;
  name: string;
  shortName: string;
  chamber: number;
  channel: number;
  isActive?: boolean;
  displaySeq?: number;
  groupId?: string;
}

export class StaticColumn {
  prop: string = '';
  header: string = '';
  required?: boolean = false;
  inputType?: string;
  valueType?: string;
  isCustom?: boolean;
  options?: any[];
  minValue?: number;
}

export interface AutomatedReport {
  automatedReportId?: number;
  status?: number;
  experimentId?: number;
  bundleId?: number;
  dailyExternalControlExperimentId: number;
  createdBy?: number;
  blobURL?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  isActive?: boolean;
  reportId?: number;
  hasDuplicateResults: boolean;
  studyType?: number;
  userSelectedResults?: UserSelectedResult[];
}

export interface UserSelectedResult {
  userSelectedResultId?: number;
  automatedReportId?: number;
  sampleId: string;
  testResultId: string;
}